import styled from "styled-components";

export const MemberInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  a{
    color: #fff;
  }
`;
export const ImageMember = styled.img`
  border-radius: 50%;
  width: 150px;
  margin-bottom: 20px;
`;
export const NameMember = styled.h1`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
`;
export const OfficeMember = styled.h2`
  font-size: 14px;
  font-weight: 400;
  color: #fff;
`;
export const YearMember = styled.h2`
  font-size: 14px;
  font-weight: 400;
  color: #fff;
`;

