import styled from "styled-components";
import theme from "../../global/colors";

export const ContainerRegister = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
`;
export const TitleRegister = styled.h3`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
`;
export const ButtonRegister = styled.button`
  width: 300px;
  background-color: ${(props) => (props.title === 'Sou uma iniciativa de inovação social' ? `${theme.colors.azulHover}` : `${theme.colors.verdeHover}`)};
  padding: 8px 12px 8px 12px;
  border: none;
  border-radius: 5px;
  margin-top: 5%;
  color: #fff;
  padding: 8px 12px 8px 12px;
  &:hover{
    background-color: ${(props) => (props.title === 'Sou uma iniciativa  de inovação social' ? `${theme.colors.azulSecondaryHover}` : `${theme.colors.verdeSecondaryHover}`)};
  }
`;
