import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, InfoWindowF, MarkerF } from '@react-google-maps/api';
import { Container, ContainerFilter, ContainerInfosMap, ContainerInputs, ContainerMap, ContainerOptions, ContainerOptionsFilter, ContainerTabs, CustomTabs, FilterBy, Icon, Input, InputFilterActor, InputFilterInitiative, InputSelect, InputText, Label, Option, ResetButton, SearchButton, TextInput, Title, TitleFilter } from "./styled";
import Tab from "react-bootstrap/Tab";
import { filterAtor, filterIniciativa, getAtor, getIniciativa } from "../../api/apiProjeto/apiProjeto";

import iconInativo from '../../assets/img/iconInativo.png'
import iconMapeada from '../../assets/img/iconMapeada.png'
import iconObservada from '../../assets/img/iconObservada.png'
import iconAcompanhada from '../../assets/img/iconAcompanhada.png'
import iconAtores from '../../assets/img/iconAtores.png'
import iconAceleracao from '../../assets/img/iconAceleracao.png'
import iconApoioTecnico from '../../assets/img/iconApoioTecnico.png'
import iconArticulacao from '../../assets/img/iconArticulacao.png'
import iconCertificacao from '../../assets/img/iconCertificacao.png'
import iconComunicacao from '../../assets/img/iconComunicacao.png'
import iconFinanciamento from '../../assets/img/iconFinanciamento.png'
import iconFormacao from '../../assets/img/iconFormacao.png'
import iconGovernamental from '../../assets/img/iconGovernamental.png'
import iconOSC from '../../assets/img/iconOSC.png'
import iconRede from '../../assets/img/iconRede.png'
import iconUniversitario from '../../assets/img/iconUniversitario.png'

function Map() {
  const [iniciativasFiltradas, setIniciativasFiltradas] = useState(null);
  const [activeTab, setActiveTab] = useState("iniciativas");
  const [activeTabWindowAtor, setActiveTabWindowAtor] = useState("suporte");
  const [activeTabWindowIniciativa, setActiveTabWindowIniciativa] = useState("iniciativa");
  const [selectedMarker, setSelectedMarker] = useState(null); // Estado para controlar o marcador selecionado

  const optionsIniciativas = [
    { titulo: "Mapeadas", ativo: 'mapeado' },
    { titulo: "Observadas", ativo: 'observado' },
    { titulo: "Acompanhadas", ativo: 'acompanhado' },
    { titulo: "Inativas", ativo: 'inativo' }
  ];

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const handleSelectWindowIniciativa = (selectedTab) => {
    setActiveTabWindowIniciativa(selectedTab);
  };

  const handleSelectWindowAtor = (selectedTab) => {
    setActiveTabWindowAtor(selectedTab);
  };

  const [filterVisible, setFilterVisible] = useState("");
  const optionsTipoOrganização = ["Associação", "Coletivo Informal", "Cooperativa", "Empreendedor Pessoa Física", "Empresa com missão social e ambiental", "Fundação", "Movimento Social", "Organização sem fins lucrativos", "Plataforma ou aplicativo", "Programa de empresa RSC", "Programa Governamental", "Programa OSC", "Programa Universitário", "Rede"];
  const optionsCausaIniciativa = ["Agricultura urbana", "Assistência social", "Ativismo, mobilização política e voluntariado", "Conservação e preservação ambiental", "Consumo consciente", "Cultura e arte", "Desenvolvimento comunitário", "Desenvolvimento urbano", "Direitos da pessoa idosa", "Direitos das crianças e adolescentes", "Direitos dos animais", "Direitos dos imigrantes e refugiados", "Educação", "Esporte e recreação", "Gênero", "Gestão de águas e saneamento", "Gestão de resíduos e reciclagem", "Inclusão de pessoas com deficiência", "População em situação de rua", "Questões Raciais e Étnicas", "Saúde", "Segurança", "Segurança Alimentar e Nutricional", "Trabalho e renda", "Transporte e mobilidade", "Uso de tecnologia para mudança social"];
  const optionsAtuacaoAtor = ["Aceleração", "Apoio Técnico", "Articulação", "Certificação", "Comunicação e Diálogo", "Controle e account.", "Financiamento", "Formação", "Incubadora", "Plataforma ou aplicativo", "Programa de empresas ou RSC", "Programa Governamental", "Programa OSC", "Programa Universitário", "Rede"];
  const optionsAbrangencia = ["Local", "Regional", "Nacional", "Internacional"];
  const [searchAtor, setSearchAtor] = useState();
  const [searchIniciativa, setSearchIniciativa] = useState();
  const [dadosAtorAlta, setDadosAtorAlta] = useState([]);

  const [searchParamsIniciativa, setSearchParamsIniciativa] = useState({
    nome: "",
    tipoOrganizacao: "",
    causas: "",
  });
  const [searchParamsAtor, setSearchParamsAtor] = useState({
    nome: "",
    abrangencia: "",
    descricaoAtuacao: "",
  });

  const handleSearchIniciativa = async () => {
    try {
      const response = await filterIniciativa(searchParamsIniciativa);
      
      setActiveTab("iniciativas");
      setSearchIniciativa(response.data);
    } catch (error) {
      console.error("Erro na busca:", error);
    }
  };

  const handleSearchAtor = async () => {
    try {
      const response = await filterAtor(searchParamsAtor);
      
      setActiveTab("atores");
      setSearchAtor(response.data);
    } catch (error) {
      console.error("Erro na busca:", error);
    }
  };

  const handleReset = async () => {
    try {
      delete searchParamsIniciativa.nome;
      delete searchParamsIniciativa.tipoOrganizacao;
      delete searchParamsIniciativa.causas;

      delete searchParamsAtor.nome;
      delete searchParamsAtor.abrangencia;
      delete searchParamsAtor.descricaoAtuacao;

      const selectElements = document.querySelectorAll('select');
      selectElements.forEach((select) => {
        select.value = '';
      });

      const textInputElements = document.querySelectorAll('input[type="text"]');
      textInputElements.forEach((input) => {
        input.value = '';
      });

      if (filterVisible === 'Iniciativas') {
        await handleSearchIniciativa();
      } else if (filterVisible === 'Atores') {
        await handleSearchAtor();
      }

    } catch (error) {
      console.error("Erro ao redefinir filtro:", error);
    }
  };

  const handleNomeChange = (e) => {
    setSearchParamsIniciativa(prevState => ({ ...prevState, nome: e.target.value }));
  };

  const handleTipoOrganizacaoChange = (e) => {
    setSearchParamsIniciativa(prevState => ({ ...prevState, tipoOrganizacao: e.target.value }));
  };

  const handleCausasChange = (e) => {
    setSearchParamsIniciativa(prevState => ({ ...prevState, causas: e.target.value }));
  };
  const handleNomeAtorChange = (e) => {
    setSearchParamsAtor(prevState => ({ ...prevState, nome: e.target.value }));
  };

  const handleAbrangenciaChange = (e) => {
    setSearchParamsAtor(prevState => ({ ...prevState, abrangencia: e.target.value }));
  };

  const handleAtuacaoChange = (e) => {
    setSearchParamsAtor(prevState => ({ ...prevState, descricaoAtuacao: e.target.value }));
  };

  useEffect(() => {
    getIniciativa()
      .then((response) => {
        setIniciativasFiltradas(response.data);
      })
      .catch((error) => {
        console.error("Erro ao carregar iniciativas:", error);
      });

    getAtor()
      .then((response) => {
        const atoresAlta = response.data.filter((ator) => {
          const infoAtuacao = ator.responseAtuacao.find((info) => info.atuacao === "ALTA");
          return infoAtuacao && infoAtuacao.descricaoAtuacao;
        }); // TODO: ativar novamente
        setDadosAtorAlta(response.data);
      })
      .catch((error) => {
        console.error("Erro ao carregar iniciativas:", error);
      });
  }, [searchAtor]);

  function getIconPathAtor(descricaoAtuacao) {
    switch (descricaoAtuacao) {
      case "Aceleração":
        return iconAceleracao;
      case "Apoio Técnico":
        return iconApoioTecnico;
      case "Articulação":
        return iconArticulacao;
      case "Certificação":
        return iconCertificacao;
      case "Comunicação e Diálogo":
        return iconComunicacao;
      case "Controle e account.":
        return iconObservada;
      case "Financiamento":
        return iconFinanciamento;
      case "Formação":
        return iconFormacao;
      case "Incubadora":
        return iconAcompanhada;
      case "Plataforma ou aplicativo":
        return iconMapeada;
      case "Programa de empresas ou RSC":
        return iconAtores;
      case "Programa Governamental":
        return iconGovernamental;
      case "Programa OSC":
        return iconOSC;
      case "Programa Universitário":
        return iconUniversitario;
      case "Rede":
        return iconRede;
      default:
        return iconInativo;
    }
  }


  function getIconPath(ativo) {
    switch (ativo) {
      case "inativo":
        return iconInativo;
      case "mapeado":
        return iconMapeada;
      case "observado":
        return iconObservada;
      case "acompanhado":
        return iconAcompanhada;
      default:
        throw new Error(`Valor 'ativo' desconhecido: ${ativo}`);
    }
  }
  
  const mapContainerStyle = { width: '100%', height: '475px' };
  const center = { lat: -22.509585, lng: -43.182305 }; // Centro do mapa ou coordenadas padrão
  
  const atoresParaRenderizar = searchAtor || dadosAtorAlta;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  if(!isLoaded){
    return <div>Loading...</div>
  }

  return (
    <Container>
      <ContainerFilter>
        <FilterBy>
          <TitleFilter>Filtro de busca por:</TitleFilter>
        </FilterBy>
        <ContainerInputs>
          <Input>
            <InputFilterInitiative type="radio" value="Iniciativas" name="gender" onChange={() => setFilterVisible("Iniciativas")} />
            <TextInput>Iniciativas Sociais</TextInput>
          </Input>
          <Input>
            <InputFilterActor type="radio" value="Atores" name="gender" onChange={() => setFilterVisible("Atores")} />
            <TextInput>Atores de Suporte</TextInput>
          </Input>
        </ContainerInputs>
      </ContainerFilter>
      {filterVisible === "Iniciativas" && (
        <>
          <ContainerOptionsFilter>
            <Label htmlFor="Nome" width="45%">
              Nome da organização
              <InputText id="Nome" type="text" onChange={handleNomeChange} />
            </Label>
            <Label htmlFor="Tipo-Organizacao" width="25%">
              Tipo da organização
              <InputSelect id="Tipo-Organizacao" onChange={handleTipoOrganizacaoChange}>
                <Option value="">Selecione...</Option>
                {optionsTipoOrganização.map((option, index) => (
                  <Option key={index} value={option}>{option}</Option>
                ))}
              </InputSelect>
            </Label>
            <Label htmlFor="Causas" width="28%">
              Causa que atua
              <InputSelect id="Causas" onChange={handleCausasChange}>
                <Option value="">Selecione...</Option>
                {optionsCausaIniciativa.map((option, index) => (
                  <Option key={index} value={option}>{option}</Option>
                ))}
              </InputSelect>
            </Label>
          </ContainerOptionsFilter>
          <SearchButton color={filterVisible} onClick={handleSearchIniciativa}>Buscar</SearchButton>
          <ResetButton onClick={handleReset}>Limpar Filtro</ResetButton>
        </>
      )
      }
      {filterVisible === "Atores" && (
        <>
          <ContainerOptionsFilter>
            <Label htmlFor="Nome" width="45%">
              Nome da organização
              <InputText id="Nome" type="text" onChange={handleNomeAtorChange} />
            </Label>
            <Label htmlFor="Atuacao" width="25%">
              Forma de atuação
              <InputSelect id="Atuacao" onChange={handleAtuacaoChange}>
                <Option value="">Selecione...</Option>
                {optionsAtuacaoAtor.map((option, index) => (
                  <Option key={index} value={option}>{option}</Option>
                ))}
              </InputSelect>
            </Label>
            <Label htmlFor="Abrangencia" width="28%">
              Escala
              <InputSelect id="Abrangencia" onChange={handleAbrangenciaChange}>
                <Option value="">Selecione...</Option>
                {optionsAbrangencia.map((option, index) => (
                  <Option key={index} value={option}>{option}</Option>
                ))}
              </InputSelect>
            </Label>
          </ContainerOptionsFilter>
          <SearchButton color={filterVisible} onClick={handleSearchAtor}>Buscar</SearchButton>
          <ResetButton onClick={handleReset}>Limpar Filtro</ResetButton>
        </>
      )
      }
      <ContainerInfosMap>
      <ContainerMap
          scrollWheelZoom={true}
          style={{ height: "475px", width: "700px" }}
        >
      <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={10}>
        {activeTab === "iniciativas" &&
          (searchIniciativa || iniciativasFiltradas) &&
          (searchIniciativa || iniciativasFiltradas)
            .filter(
              (iniciativa) =>{
                return iniciativa.verificado === true && iniciativa.autorizoDivulgacao;
              })
            .map((iniciativa, index) => {
              const iconPath = getIconPath(iniciativa.ativo);
              const position = { lat: iniciativa.latitude, lng: iniciativa.longitude };

              return (
                <MarkerF
                  key={index}
                  position={position}
                  icon={{
                    url: iconPath,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  onClick={() => setSelectedMarker({ type: 'iniciativa', index })} // Define o marcador selecionado ao clicar
                >
                  {selectedMarker?.type === 'iniciativa' && selectedMarker.index === index && (
                    <InfoWindowF 
                      onCloseClick={() => setSelectedMarker(null)}
                      position={position} 
                    >
                      <CustomTabs
                        activeKey={activeTabWindowIniciativa}
                        onSelect={handleSelectWindowIniciativa}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey= "iniciativa" title="Iniciativa">
                          <div>
                            <h5>{iniciativa.nome}</h5>
                            <p>
                              {iniciativa.logradouro}, {iniciativa.bairro}, {iniciativa.localidade}
                            </p>
                            <h6>Contato</h6>
                            <p>{iniciativa.email}</p>  
                            <p><b>Site <br /></b>{iniciativa.site}</p>
                          </div>
                        </Tab>
                        <Tab eventKey= "causa" title="Causa">
                          <div>
                          <h5>{iniciativa.nome}</h5>
                            <p><br /><b>Causas</b><br />{iniciativa.causa1}, {iniciativa.causa2}, {iniciativa.causa2}</p>
                            <br />
                            <p><b>Público Alvo</b><br />{iniciativa.categoriaPublicoIniciativa1}, {iniciativa.categoriaPublicoIniciativa2}, {iniciativa.categoriaPublicoIniciativa3}</p>
                          </div>
                        </Tab>
                        <Tab eventKey= "solucoes" title="Soluções">
                          <div>
                          <h5>{iniciativa.nome}</h5>
                            <p><br /><b>Problemas Públicos</b><br />{iniciativa.problemasPublicos}</p>
                            <br />
                            <p><b>Soluções</b><br />{iniciativa.solucaoProblemas}</p>
                          </div>
                        </Tab>
                        <Tab eventKey= "apoiadores" title="Apoiadores">
                          <div>
                            <h5>{iniciativa.nome}</h5>
                              {iniciativa.apoioFinaceiro ? (
                                <p><br /><b>Apoio financeiro </b><br /> {iniciativa.suporteFinanceiro} <br /> {iniciativa.outroApoio}<br/></p>
                              ) : (
                                <br />
                              )}
                            {iniciativa.indicacaoIniciativa ? (
                              <p><b>Indicação</b><br /> {iniciativa.indicacaoIniciativa} </p>
                            ) : (
                              <br />
                            )}
                          </div>
                        </Tab>
                      </CustomTabs>
                      
                    </InfoWindowF>
                  )}
                </MarkerF>
              );
            })}

        {activeTab === "atores" &&
          atoresParaRenderizar
            .filter(
              (ator) =>{
                return ator.verificado === true && ator.autorizoDivulgacao;
              }
            )
            .map((ator, index) => {
              const infoAtuacao = ator.responseAtuacao?.find(
                (info) => info.atuacao    
              );

              const iconPath = infoAtuacao
                ? getIconPathAtor(infoAtuacao.descricaoAtuacao)
                : iconInativo;

              const position = { lat: ator.latitude, lng: ator.longitude };
              
              return (
                <MarkerF
                  key={index}
                  position={position}
                  icon={{
                    url: iconPath,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  onClick={() => setSelectedMarker({ type: 'ator', index })} 
                >
                  
                  {selectedMarker?.type === 'ator' && selectedMarker.index === index && (
                    <InfoWindowF
                      onCloseClick={() => setSelectedMarker(null)} 
                      position={position} 
                    >
                      <CustomTabs
                        activeKey={activeTabWindowAtor}
                        onSelect={handleSelectWindowAtor}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey= "suporte" title="Suporte">
                          <div>
                              <h5>{ator.nome}</h5>
                              <p>
                                {ator.logradouro}, {ator.bairro}, {ator.localidade}
                              </p>
                              
                              <h6>Contato</h6>
                               
                              <p>
                                {ator.email}
                                <br />
                                <br />
                                <h6>Site</h6>

                              {ator.site}</p>
                          </div>
                        </Tab>
                        <Tab eventKey="areaAtuacao" title="Area de Atuação">
                          <div>
                          <br />
                              {infoAtuacao ? (
                                <p><b>Atuação principal </b><br /> {infoAtuacao.descricaoAtuacao}</p>
                              ) : (
                                <p><b>Atuação principal</b><br /> Não informada</p>
                              )}
                              <p>
                                <b>Publico Alvo</b> <br />{ator.responseFrequencia.map((element) => { return element.descricaoFrequenciaPublico}).join(', ')}
                              </p>
                              <p>
                                <b>Escala</b><br />{ator.abrangencia}
                              </p>
                              <p>
                                <b>Atividades Públicas</b><br />{ator.atividadesPublicos ? ator.atividadesPublicos : "Não informadas"}
                              </p>
                          </div>  
                        </Tab>  
                      </CustomTabs>
                    </InfoWindowF>
                  )}
                </MarkerF>
              );
              
            })}
      </GoogleMap>
        </ContainerMap>
        <ContainerTabs>
          <CustomTabs
            activeKey={activeTab}
            onSelect={handleSelect}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="iniciativas" title="Iniciativas Sociais">
              <div style={{ height: '475px', overflowY: 'scroll' }}>
                {optionsIniciativas.map((opcao, index) => (
                  <ContainerOptions key={index}>
                    <Icon src={getIconPath(opcao.ativo)} alt={opcao.ativo} />
                    <Title>{opcao.titulo}</Title>
                  </ContainerOptions>
                ))}
              </div>
            </Tab>
            <Tab eventKey="atores" title="Atores de Suporte">
              <div style={{ maxHeight: '475px', overflowY: 'scroll' }}>
                {optionsAtuacaoAtor.map((opcao, index) => (
                  <ContainerOptions key={index}>
                    <Icon src={getIconPathAtor(opcao)} alt={opcao} />
                    <Title>{opcao}</Title>
                  </ContainerOptions>
                ))}
              </div>
            </Tab>
          </CustomTabs>
        </ContainerTabs>
      </ContainerInfosMap>
    </Container>
  );
}

export default Map;
