import Accordion from "react-bootstrap/Accordion";
import { Checkbox, ContainerAccordion, Label } from "./styled";
import { PiAsteriskThin } from "react-icons/pi";

function AccordionForm({
  description,
  options,
  label,
  onCheckboxChange,
  width,
  height,
  tipoStatus
}) {
  const handleCheckboxChange = (checked, value) => {
    onCheckboxChange(checked, value);
  };

  return (
    <Label>
      {label} <PiAsteriskThin />
      <ContainerAccordion width={width} height={height} backgroundColor={tipoStatus}>
        <Accordion>
          <Accordion.Item eventKey="0" >
            <Accordion.Header >{description}</Accordion.Header>
            <Accordion.Body>
              {Array.isArray(options) &&
                options.map((option, index) => (
                  <Checkbox key={index}>
                    <input
                      type="checkbox"
                      value={option}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e.target.checked,
                          option,
                          "causaEspecifica"
                        )
                      }
                    />
                    <span>{option}</span>
                  </Checkbox>
                ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </ContainerAccordion>
    </Label>
  );
}

export default AccordionForm;
