import theme from "../../global/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
export const ContainerPanel = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const ContainerNotLogged = styled.div`
  background-color: ${theme.colors.azulMarinho};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 2rem;
`

export const NotLoggedLogo = styled.img`
  width: 25%;
`

export const NotLoggedText = styled.p`
  font-size: 42px;
  font-weight: 600;
  color: white;
`