import styled from "styled-components";
import theme from "../../global/colors";
import Tabs from "react-bootstrap/Tabs";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3%;
`;
export const Title = styled.h1`
  margin-bottom: 3%;
  font-size: 30px;
  font-weight: 600;
  border-left: 2px solid ${theme.colors.verdeEscuro};
  padding-left: 15px;
  color: #424242;
`;
export const Text = styled.p`
  margin: 0;
`;

export const CustomTabs = styled(Tabs)`
  .nav-link.active {
    background-color: ${(props) =>
      props.activeTab === "iniciativas"
        ? `${theme.colors.azulHover}`
        : `${theme.colors.verdeHover}`};
    color: #fff;
    border: none;
  }
  .nav-link {
    color: #000;
  }
`;

export const ContainerTabs = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 8%;
`;

export const ContainerChart = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.borderGrey};
  margin-top: 20px;
  margin-bottom: 60px;

  rect.highcharts-background {
    fill: none;
  }
`;
