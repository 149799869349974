import styled from "styled-components";
import theme from "../../global/colors";

export const Checkbox = styled.label`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 4px 0;

  input {
    border: 0.5px solid ${theme.colors.borderGrey};
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
  }
`;

export const ContainerAccordion = styled.div`
  
  margin-top: 4px;
  .accordion-header {
    border-radius: 2px;

    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "100%"};
  }
  .accordion-item {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 0.5px solid ${theme.colors.borderGrey};
   
  }
  .accordion-button.collapsed {
    
  }

  .accordion-button {
   
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "100%"};

    
    
  }

  .accordion-button:not(.collapsed) {
    
    background-color: #dcdcdc;
    box-shadow: none;
    color: black;
    
  }
  .accordion-button:focus {
    box-shadow: none;
  }
`;

export const Label = styled.label`
  color: ${(props) =>
    props.require === true ? `${theme.colors.red}` : `${theme.colors.black}`};
  font-size: 14px;
  font-weight: 400;
  width: ${(props) => props.width || "100%"};
  min-width: 250px;
`;
