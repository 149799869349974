import { Outlet, useLocation } from "react-router-dom";
import LoadingLogo from './assets/img/Logo2-removebg-preview.png'
import Header from "./components/Header";
import Footer from "./components/Footer";
import { GlobalStyle } from "./global/globalStyle";
import VLibras from "@djpfs/react-vlibras";
import { LoadingContainer, LoadingLogoImg } from "./pages/Home/styled";
import { ring } from 'ldrs'
import { useContext } from "react";
import { LoadingContext } from "./context/LoadingContext";

function App() {
  const location = useLocation();
  const hideHeaderFooterRoutes = ["/painel-administrador", "/login-administrador"];

  const shouldHideHeaderFooter = hideHeaderFooterRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  const {isLoading} = useContext(LoadingContext);

  ring.register()

  return (
    <>
      <GlobalStyle />
      {isLoading && <LoadingContainer>
        <LoadingLogoImg src={LoadingLogo} alt="Logo Observatorio"/>
        <l-ring
          size="220"
          stroke="5"
          bg-opacity="0.1"
          speed="3"
          color="#CDFB18" 
        ></l-ring>
      </LoadingContainer>}
      {!shouldHideHeaderFooter && <Header />}
      <Outlet />
      {!shouldHideHeaderFooter && <Footer />}
    </>
  );
}

export default App;
