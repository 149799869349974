import { useEffect } from "react";
import { createContext, useState } from "react";

export const LoadingContext = createContext();

export const LoadingContextProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isLogged, setIsLogged] = useState(false);

    useEffect(()=>{
        if(sessionStorage.getItem("usuarioLogado")) {
            setIsLogged(true);
        };
    },[])

    return(
        <LoadingContext.Provider
        value={{
            isLoading,
            setIsLoading,
            isLogged,
            setIsLogged
        }}>
            { children }
        </LoadingContext.Provider>
    )
}