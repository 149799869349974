import React from 'react'
import { ContainerBackgroundBanner, ContainerBannerInfos, TitleBanner } from './styled'

function Banner({ title, background }) {
  return (
    <ContainerBackgroundBanner background={background}>
      <ContainerBannerInfos>
        <TitleBanner>
          {title}
        </TitleBanner>
      </ContainerBannerInfos>
    </ContainerBackgroundBanner>
  )
}

export default Banner