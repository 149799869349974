const globalColors = {
  colors: {
    primary: "#f94e67",
    secondary: "#f69051",
    green: "#00bc92",
    black: "#262626",
    red: "#ff200c",
    borderGrey: "#bdbdbd",
    background: "#f5f5f5",
    yellow: "#f9df4e",
    vine: "#ad1457",
    blue: "#4edff9",
    lightGrey: "#dcdcdc",
    primaryHover: "#df2248",
    secondaryHover: "#e87411",
    
    azulMarinho: "#091924",
    azulClaro: "#0ce0df",
    verdeClaro: "#dfecb6",
    verdeEscuro: "#78913d",
    cinza: "#646c74",
    cinzaClaro: "#7c8494",
    
    verdeHover: "#66AA13",
    azulHover: "#1D6EA6",

    azulSecondaryHover:"#227DBB",
    verdeSecondaryHover:"#74AA33"

  },
};

export default globalColors;
