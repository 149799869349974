import styled from "styled-components";
import { FaInstagram } from "react-icons/fa6";

export const FooterTag = styled.footer`
  background-color: #2d3a4a;
  color: white;
  padding-top: 3%;
  padding-bottom: 3%;
  width: 100%;
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
`;

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: #fff;
  }
  a:hover {
      text-decoration: underline;
      color: #fff;
    }
`;

export const ContainerSociais = styled.div `
  width: 100%;
  display: flex;
  gap: 10px; 
  align-items: center;

  a {
    text-decoration: none;
  }
`;
export const TextInfos = styled.p`
  margin-bottom: 0;
`;

export const TitleInfos = styled.h1`
  font-size: 22px;
  color: white;
  margin-bottom: 20px;
`;

export const IconInstagram = styled(FaInstagram)`
  width: 25px;
  height: 25px;
`;

export const DireitoTag = styled.div`
  display: flex;
  background-color: ;
  color: white;
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px; 
  position: relative;
  margin-top: 40px;
  bottom: 0;
`;

