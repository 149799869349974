import styled from "styled-components";
import theme from "../../global/colors";
import Row from "react-bootstrap/Row";
import { FaUsersCog } from "react-icons/fa";
import { BsBuildingFillCheck } from "react-icons/bs";
import { PiListMagnifyingGlassBold } from "react-icons/pi";
import Tabs from "react-bootstrap/Tabs";
import { FaPlus } from "react-icons/fa6";
import { BsPersonFillCheck } from "react-icons/bs";
import { Breadcrumb } from "react-bootstrap";
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";

export const Titulo = styled.h1`
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 400;
`;
export const IconInitiative = styled(BsBuildingFillCheck)`
  align-self: center;
  width: 20px;
  height: 20px;
  margin-right: 1px;
`;
export const IconSuport = styled(BsPersonFillCheck)`
  align-self: center;
  width: 20px;
  height: 20px;
  margin-right: 1px;
`;
export const IconList = styled(PiListMagnifyingGlassBold)`
  align-self: center;
  width: 20px;
  height: 20px;
  margin-right: 1px;
`;
export const IconUsers = styled(FaUsersCog)`
  align-self: center;
  width: 20px;
  height: 20px;
  margin-right: 1px;
`;

export const CustomRow = styled(Row)`
  display: flex:
  flex-wrap: nowrap;
  height: 100vh;
  margin: 0;

  .col-sm-3 {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 2px 4px rgba(0, 0, 0, 0.21);
    padding: 0 0.2px;
    width: 12%;
    min-width: 200px;
    min-height: 100%;
    background-color: ${theme.colors.azulMarinho};
    flex-grow: 0;
  }
  .col-sm-9 {
    display: flex;
    flex-direction: column;
    margin: 4rem 4%;
    padding: 1rem 1%;
    flex-grow: 1; 
  }
  .tab-content {
    display: flex;
    color: #000;

    border-radius: 3px;
    width: 100%;
  }
  .tab-content > .active {
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    width: 100%;
    height: 100%;
  }
  .nav-link.active {
    border-left: solid 3px green;
    border-radius: 0;
    background-color: ${theme.colors.borderGrey};
    color: #000;
  }
  .nav-link {
    display: flex;
    color: white;
    gap: 16px;
    border: none;
    border-radius: none;
  }
`;

export const CustomTabs = styled(Tabs)`
  .nav-link.active {
    border-radius: 6px 6px 0px 0 !important;
    background-color: ${(props) =>
      props.activetab === "iniciativas"
        ? `${theme.colors.azulHover}`
        : `${theme.colors.verdeHover}`};
    color: #fff;
    border: none;
  }
  .nav-link {
    color: #000;
  }

  .tab-content {
    width: auto;
    padding: 0;
  }
`;

export const StatusColorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RegisterStatusColors = styled.div`
  display: flex;
  width: auto;
  margin-bottom: 2rem;
  gap: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 50px;
  padding: 15px;
  border-left: 2px solid ${theme.colors.borderGrey};
  border-radius: 4px;
`;
export const TextStatusColor = styled.p`
  margin: 0;
  color: ${(props) => {
    switch (props.registerStatus) {
      case "inativa":
        return theme.colors.borderGrey;
      case "mapeada":
        return theme.colors.green;
      case "observada":
        return theme.colors.vine;
      case "acompanhada":
        return theme.colors.blue;
      case "excluidas":
        return theme.colors.primary;
    }
  }};
`;

export const StatusColor = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin: 0 6px;
  border: none;
  border-radius: 10px;
  color: #262626;
  background-color: ${(props) => {
    switch (props.registerstatus) {
      case "inativa":
        return theme.colors.borderGrey;
      case "mapeada":
        return theme.colors.green;
      case "observada":
        return theme.colors.vine;
      case "acompanhada":
        return theme.colors.blue;
      case "excluidas":
        return theme.colors.primary;
      case "master":
        return theme.colors.secondary;
      case "admin":
        return theme.colors.primary;
    }
  }};
`;

export const ButtonTab = styled.button`
  display: flex;
  align-items: center;
  width: 8rem;
  padding: 8px 4px;
  border: none;
  border-radius: 6px 6px 0 0;
  color: #fff;
  background-color: ${theme.colors.azulHover};
`;

export const PlusIcon = styled(FaPlus)`
  margin: 2px;
  width: 16px;
  height: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 40px;
  width: 100%;
  border-bottom: solid 1px #dee2e6;
`;
export const RegisterAdmContainer = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
`;
export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const UserImg = styled.img`
  width: 180px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 70px;
`;
export const UserImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0px;
`;

export const BreadCrumb = styled(Breadcrumb)`
  margin-bottom: 4rem;
  a {
    color: green;
  }
`;

export const LoginButton = styled.button`
  background-color: ${theme.colors.primary};
  width: 100%;
  border: none;
  border-radius: 10px;
  margin-top: 10%;
  padding: 1% 0 1% 0;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
`;

export const InputText = styled.input`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: ${(props) =>
    props.disabled === true ? "#e0e0e0" : "#fff"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
  }
`;
export const Label = styled.label`
  color: ${(props) =>
    props.require === true ? `${theme.colors.red}` : `${theme.colors.black}`};
  font-size: 14px;
  font-weight: 400;
  width: ${(props) => props.width || "100%"};
  /* min-width: 147px; */
  margin: 6px 0;
`;

export const ContainerPassword = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ShowEye = styled(LuEye)`
  width: 25px;
  height: 25px;
`;

export const HideEye = styled(LuEyeOff)`
  width: 25px;
  height: 25px;
`;
export const FormsModal = styled.form``;

export const ContainerUsuario = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  margin-bottom: 20px;
  position: relative;
  background-color: ${(props) =>
    props.user === "MASTER"
      ? `${theme.colors.secondary}`
      : `${theme.colors.primary}`};
`;

export const TitleUsuario = styled.h1`
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  width: 100%;
  background-color: #fff;
  height: 60px;
  border-top: 1px solid ${theme.colors.borderGrey};
  border-right: 1px solid ${theme.colors.borderGrey};
  border-bottom: 1px solid ${theme.colors.borderGrey};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-left: 6px;
`;

export const BtnExcluir = styled.button`
  position: absolute;
  border: 0.005px solid ${theme.colors.cinzaClaro};
  right: 15px;
  height: 35px;
  border-radius: 8px;
  padding: 0 10px 0 10px;
  transition-duration: 0.1s;
  &:hover {
    color: white;
    background-color: ${theme.colors.primary};
  }
`

export const BtnEditar = styled.button`
  position: absolute;
  border: 0.005px solid ${theme.colors.cinzaClaro};
  right: 105px;
  height: 35px;
  border-radius: 8px;
  padding: 0 10px 0 10px;
  transition-duration: 0.1s;
  &:hover {
    color: white;
    background-color: ${theme.colors.secondary};
  }
`
