import React from "react";
import {
  Container,
  ContainerInfos,
  ContainerSociais,
  FooterTag,
  IconInstagram,
  TextInfos,
  TitleInfos,
  DireitoTag
} from './styled';
import { Link } from "react-router-dom";

function Footer() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  function handleClick(){
    scrollToTop();
  };
  return (
    <FooterTag>
      <Container>
        <ContainerInfos>
          <TitleInfos>Mapa do site</TitleInfos>
          <Link to={""} onClick={()=> handleClick()}>Início</Link>
          <Link to={"/sobre-nos"} onClick={()=> handleClick()}>Quem somos</Link>
          <Link to={"/ecossistema"} onClick={()=> handleClick()}>Organizações/Iniciativas registradas</Link>
          <Link to={"/grafico"} onClick={()=> handleClick()}>Gráfico</Link>
          <Link to={"/problemas-publicos"} onClick={()=> handleClick()}>Problemas Públicos</Link>
          <Link to={"/contato"} onClick={()=> handleClick()}>Contato</Link>
        </ContainerInfos>
        <ContainerInfos>
          <TitleInfos>Contato</TitleInfos>
          <TextInfos>eisp@ippur.ufrj.br</TextInfos>
          <TextInfos>Petrópolis - RJ</TextInfos>
        </ContainerInfos>
        <ContainerInfos>
          <TitleInfos>Links Úteis</TitleInfos>
          <Link to={"https://crises.uqam.ca/"} onClick={()=> handleClick()}>Centre de Recherche sur les innovations Sociales (Québec – Canada)</Link>
          <Link to={"https://www.si-drive.eu/?p=2493"} onClick={()=> handleClick()}>Mapping the World of Social Innovation (European Union)</Link>
          <Link to={"https://emes.net/"} onClick={()=> handleClick()}>International Research Network for Social Enterprise (EMES)</Link>
          <Link to={"https://www.istr.org/default.aspx"} onClick={()=> handleClick()}>International Society for Third Sector Research (ISTR)</Link>
          <Link to={"https://www.ciriec.uliege.be/"} onClick={()=> handleClick()}>CIRIEC - International Center of Research and Information on the Public, Social and Cooperative Econ</Link>
          <Link to={"https://via.ufsc.br/mapa-de-habitats-de-inovacao-de-sc/"} onClick={()=> handleClick()}>Mapa de habitats de inovação de Santa Catarina</Link>
          <Link to={"https://ipgpar.org.br/contato/"} onClick={()=> handleClick()}>Instituto Philippe Guédon Gestão Participativa</Link>
        </ContainerInfos>
        <ContainerInfos>
          <TitleInfos>Redes Sociais</TitleInfos>
          <ContainerSociais>
            <IconInstagram />
            <Link to={"https://instagram.com/eispetropolis?igshid=MTk0NTkyODZkYg=="} target="_blank">@eispetropolis</Link>
          </ContainerSociais>
        </ContainerInfos>
      </Container>
      <DireitoTag>
      2024 © Observatório de Inovação Social de Petrópolis | Todos os direitos reservados.
| LAPIS - Laboratório Ação Pública e Inovações Sociais
      </DireitoTag>
    </FooterTag>
    
  );
}

export default Footer;
