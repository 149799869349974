import styled from "styled-components";
import theme from "../../global/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  align-items: center;
`;

export const Infos = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const TitleFinancier = styled.h1`
  text-align: center;
  width: 100%;
  align-self: flex-start;
  font-size: 30px;
  color: #424242;
  margin-bottom: 2%;
`;

export const ContainerFinanciers = styled.div`
  display: flex;
  margin-bottom: 5%;
  gap: 2%;
  justify-content: center;
`;

export const ImageFinancier = styled.img`
  width: 220px;
  height: auto;
  object-fit: contain;
`;


export const Line = styled.hr`
  color: ${theme.colors.azulHover};
  width: 30%;
  border: 2px solid ${theme.colors.azulHover};
`;
