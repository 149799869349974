import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import AdmImg from "../../assets/img/Logo2-removebg-preview.png";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from 'react-bootstrap/Modal';

import {
  CustomRow,
  IconInitiative,
  IconList,
  IconSuport,
  IconUsers,
  Titulo,
  ButtonTab,
  PlusIcon,
  ButtonContainer,
  RegisterAdmContainer,
  UsersContainer,
  UserImg,
  UserImgContainer,
  BreadCrumb,
  StatusColor,
  RegisterStatusColors,
  CustomTabs,
  TextStatusColor,
  StatusColorContainer,
  InputText,
  Label,
  ContainerPassword,
  LoginButton,
  ShowEye,
  HideEye,
  FormsModal,
  ContainerUsuario,
  TitleUsuario,
  BtnExcluir,
  BtnEditar,
} from "./styled";

import { useEffect, useState, useContext } from "react";
import RegistersFilterAdmPanelInitiative from "../RegistersFilterAdmPanelInitiative";
import RegistersFilterAdmPanelSuport from "../RegisterFilterAdmPanelSuport";
import { useForm } from "react-hook-form";
import { addUser, deleteUser, getUsuario, updateUser } from "../../api/apiProjeto/apiProjeto";
import { LoadingContext } from "../../context/LoadingContext";

function TabMenuAdmPanel() {
  const [activeTab, setActiveTab] = useState("iniciativas");
  const [showPassword, setShowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showExcluirModal, setShowExcluirModal] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [userToDelete, setUserToDelete] = useState();
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedAtivo, setUpdatedAtivo] = useState();
  const [updatedRole, setUpdatedRole] = useState();
  const [updatedId, setUpdatedId] = useState();
  const { setIsLoading } = useContext(LoadingContext);

  const { register, handleSubmit, reset } = useForm();

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const handleCloseEdit = () => {
    setUpdatedEmail("");
    setShowEditModal(false);
  }

  const handleCloseExcluir = () => {
    setUserToDelete('');
    setShowExcluirModal(false);
  }

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };
  const handlePassword = () => {
    setShowPassword(!showPassword);
  }

  const handleEditar = (user) => {
    setUpdatedId(user.usuarioId);
    setUpdatedEmail(user.email);
    setUpdatedAtivo(user.ativo);
    setUpdatedRole(user.role);
    setShowEditModal(true);
  }

  const handleExcluir = (user) => {
    setUserToDelete(user);
    setShowExcluirModal(true);  
  }

  const handleDelete = async () => {
    try {
      await deleteUser(userToDelete.usuarioId);
      handleCloseExcluir();
      handleGetUsers();
    } catch(err) {
      console.log(err);
    }
  }

  const handleEditUser = async () => {
    let editedUser = {
      usuarioId: updatedId,
      email: updatedEmail,
      ativo: updatedAtivo,
      role: updatedRole
    }
    setIsLoading(true);
    try {
      await updateUser(editedUser)
      handleCloseEdit();
      handleGetUsers();
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false);
  }

  const handleRegister = async (data) => {
    try {
      const userData = { ...data, role: "ADMIN", ativo: true };
      const regexp = /^[^\s@]+@[^\s@]+.[^\s@]+$/;

      if(regexp.test(data.email) == true){
        console.log(userData)
        await addUser(userData);
        reset();
        handleClose();
        alert("Usuário cadastrado com sucesso!");

        handleGetUsers();
      } else {
        alert("Email inválido!");
      }
      
    } catch (error) {
      console.error('Erro no registro do usuário', error);
    }
  };

  const handleGetUsers = () => {
    setIsLoading(true)
    getUsuario()
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar usuários:", error);
      });
    setIsLoading(false)
  } 

  useEffect(() => {
    handleGetUsers()
  }, []);

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <CustomRow>
        <Col sm={3}>
          <UserImgContainer>
            <UserImg src={AdmImg} />
          </UserImgContainer>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">
                <IconInitiative />
                Novas iniciativas
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                <IconSuport />
                Novos Atores
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">
                <IconList />
                Lista completa
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth">
                <IconUsers />
                Administradores
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <BreadCrumb>
            <Breadcrumb.Item href="/">Página inicial</Breadcrumb.Item>
            <Breadcrumb.Item active>Painel de administradores</Breadcrumb.Item>
          </BreadCrumb>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Titulo>Iniciativas cadastradas</Titulo>
              <ButtonContainer>
                <ButtonTab>...</ButtonTab>
              </ButtonContainer>
              <RegistersFilterAdmPanelInitiative
                tipoLista={"cadastroIniciativa"}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Titulo>Atores de suporte cadastrados</Titulo>
              <ButtonContainer>
                <ButtonTab>...</ButtonTab>
              </ButtonContainer>
              <RegistersFilterAdmPanelSuport tipoLista={"cadastroAtores"} />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <Titulo>Lista completa</Titulo>

              <CustomTabs
                activetab={activeTab}
                onSelect={handleSelect}
                defaultActiveKey="iniciativas"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="iniciativas" title="Iniciativas Sociais">
                  <RegisterStatusColors>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"inativa"} />
                      <TextStatusColor>Inativas</TextStatusColor>
                    </StatusColorContainer>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"mapeada"} />
                      <TextStatusColor>Mapeadas</TextStatusColor>
                    </StatusColorContainer>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"observada"} />
                      <TextStatusColor>Observadas</TextStatusColor>
                    </StatusColorContainer>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"acompanhada"} />
                      <TextStatusColor>Acompanhadas</TextStatusColor>
                    </StatusColorContainer>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"excluidas"} />
                      <TextStatusColor>Excluídas</TextStatusColor>
                    </StatusColorContainer>
                  </RegisterStatusColors>
                  <RegistersFilterAdmPanelInitiative tipoLista={"listaCompleta"} />
                </Tab>
                <Tab eventKey="atores" title="Atores de Suporte">
                  <RegisterStatusColors>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"mapeada"} />
                      <TextStatusColor>Verificados</TextStatusColor>
                    </StatusColorContainer>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"excluidas"} />
                      <TextStatusColor>Excluídos</TextStatusColor>
                    </StatusColorContainer>
                  </RegisterStatusColors>
                  <RegistersFilterAdmPanelSuport tipoLista={"listaCompleta"} />
                </Tab>
              </CustomTabs>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              <Titulo>Cadastro de administradores</Titulo>
              <RegisterAdmContainer>
                <ButtonContainer>
                  <ButtonTab variant="primary" onClick={handleShow}>
                    <PlusIcon />
                    Novo usuário
                  </ButtonTab>
                </ButtonContainer>
                <UsersContainer>
                  <RegisterStatusColors>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"master"} />
                      <TextStatusColor>Master</TextStatusColor>
                    </StatusColorContainer>
                    <StatusColorContainer>
                      <StatusColor registerstatus={"admin"} />
                      <TextStatusColor>Admin</TextStatusColor>
                    </StatusColorContainer>
                  </RegisterStatusColors>
                  {usuarios.map((item, index) => (
                    // TROCAR A KEY POR item.id
                    <ContainerUsuario key={index} user={item.role}>
                      <TitleUsuario>{item.email}</TitleUsuario>
                      <BtnEditar user={item.role} onClick={() => handleEditar(item)}>EDITAR</BtnEditar>
                      <BtnExcluir user={item.role} onClick={() => {handleExcluir(item)}}>EXCLUIR</BtnExcluir>
                    </ContainerUsuario>
                  ))}
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Cadastro</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <FormsModal onSubmit={handleSubmit(handleRegister)}>
                        <Label htmlFor="email">
                          Email
                          <InputText
                            id="email"
                            type='text'
                            {...register("email")}
                            required={true}
                            onChange={(e) => e.target.value = e.target.value.trim()}
                          />
                        </Label>
                        <Label htmlFor="senha">
                          Senha
                          <ContainerPassword>
                            <InputText
                              id="senha"
                              type={showPassword ? "text" : "password"}
                              {...register("senha")}
                              required={true}
                              onChange={(e) => e.target.value = e.target.value.trim()}
                            />
                            {showPassword ? <ShowEye onClick={handlePassword} /> : <HideEye onClick={handlePassword} />}
                          </ContainerPassword>
                        </Label>
                        <LoginButton type="submit">Cadastrar</LoginButton>
                      </FormsModal>
                    </Modal.Body>
                  </Modal>

                  <Modal 
                    show={showEditModal} 

                    onHide={handleCloseEdit}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Editar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <FormsModal onSubmit={handleSubmit(handleEditUser)}>
                        <Label htmlFor="emailEdit">
                          Email
                          <InputText
                            id="emailEdit"
                            type='text'
                            required={true}
                            value={updatedEmail}
                            onChange={(e) => setUpdatedEmail(e.target.value.trim())}
                          />
                        </Label>
                        <LoginButton type="submit">Concluir</LoginButton>
                      </FormsModal>
                    </Modal.Body>
                  </Modal>

                  <Modal 
                    show={showExcluirModal} 
                    onHide={handleCloseExcluir}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Excluir</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <FormsModal onSubmit={handleSubmit(handleDelete)}>
                        <Titulo>
                          Deseja mesmo excluir o usuário {userToDelete && userToDelete.email}?
                        </Titulo>
                        <LoginButton type="submit">Sim</LoginButton>
                      </FormsModal>
                    </Modal.Body>
                  </Modal>
                </UsersContainer>
              </RegisterAdmContainer>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </CustomRow>
    </Tab.Container>
  );
}

export default TabMenuAdmPanel;
