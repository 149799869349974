import React, { useContext, useEffect, useState } from "react";
import MapHome from "../../components/Map";
import {
  BodyHome,
  Container,
  ContainerQuantity,
  ContainerQuantityIndicator,
  TextQuantity,
  TitleQuantity,
} from "./styled";
import BannerHome from "../../components/BannerHome";
import Financiers from "../../components/Financiers";
import {
  getQuantidadeAtores,
  getQuantidadeIniciativas,
} from "../../api/apiProjeto/apiProjeto";
import { LoadingContext } from "../../context/LoadingContext";

function Home() {
  const [numAtores, setNumAtores] = useState(0);
  const [numIniciativas, setNumIniciativas] = useState(0);

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const quantidadeAtores = await getQuantidadeAtores();
        const quantidadeIniciativas = await getQuantidadeIniciativas();

        setNumAtores(quantidadeAtores);
        setNumIniciativas(quantidadeIniciativas);
      } catch (error) {
        console.error("Erro ao obter quantidades:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Container>
      <BannerHome />
      <BodyHome>
        <MapHome />
        <ContainerQuantity>
          <TextQuantity>
          O OBISP é um espaço dedicado a exibir, conectar, apoiar e monitorar os participantes da rede do Ecossistema de Inovação Social de Petrópolis,
           abrangendo tanto atores de suporte quanto iniciativas de inovação social. 
          </TextQuantity>               
          <ContainerQuantityIndicator>
            <TitleQuantity color="#66AA13">
              ATORES DE SUPORTE <br />
              {numAtores}
            </TitleQuantity>
            <TitleQuantity color="#1D6EA6">
              INICIATIVAS SOCIAIS <br />
              {numIniciativas}
            </TitleQuantity>
          </ContainerQuantityIndicator>
        </ContainerQuantity>
        <Financiers />
      </BodyHome>
    </Container>
  );
}

export default Home;
