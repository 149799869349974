import styled from "styled-components";
import theme from "../../global/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  width: 100%;
  padding: 1% 0;
  margin-bottom: 20px;
`;

export const InputRadio = styled.input.attrs({ type: "radio" })`
  width: 20px;
  height: 20px;

  &:checked {
    accent-color: ${(props) =>
      props.registerType === "iniciativa"
        ? `${theme.colors.primary}`
        : "#e87411"};
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const TextInput = styled.p`
  margin: 0;
  font-size: 18px;
`;

export const ContainerExpand = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Label = styled.label`
  margin-bottom: 1px;
  font-weight: 400;
  width: ${(props) => props.width || "100%"};
`;

export const SubText = styled.p`
  font-size: 12px;
`;

export const InputText = styled.input`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: ${(props) =>
    props.disabled === true ? "#e0e0e0" : "#fff"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 189, 189, 0.577468487394958);
  }
`;
export const LabelText = styled.label`
  color: ${(props) =>
    props.require === true ? `${theme.colors.red}` : `${theme.colors.black}`};
  font-size: 14px;
  font-weight: 400;
  width: ${(props) => props.width || "100%"};
`;

export const LabelTextDuo = styled.label`
  color: ${(props) =>
    props.require === true ? `${theme.colors.red}` : `${theme.colors.black}`};
  font-size: 14px;
  font-weight: 400;
  /* min-width: 147px; */
`;

export const Option = styled.option`
  font-size: 14px;
  font-weight: 400;
`;

export const InputSelect = styled.select`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: ${(props) => props.widthInput || "100%"};
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: ${(props) =>
    props.disabled === true ? "#e0e0e0" : "#fff"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 189, 189, 0.577468487394958);
  }
`;

export const InputTextArea = styled.textarea`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: ${(props) =>
    props.disabled === true ? "#e0e0e0" : "#fff"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: ${(props) => props.height || "2rem"};
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 189, 189, 0.577468487394958);
  }
`;

export const Checkbox = styled.label`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 4px 0;

  input {
    border: 0.5px solid ${theme.colors.borderGrey};
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
  }
`;

export const ContainerAccordion = styled.div`
  width: 100%;
  margin-top: 4px;
  .accordion-header {
    border-radius: 2px;
    background-color: ${(props) =>
      props.disabled === true ? "#e0e0e0" : "#fff"};
    height: 36px;
  }
  .accordion-item {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 0.5px solid ${theme.colors.borderGrey};
  }
  .accordion-button.collapsed {
    border-radius: 10px !important;
  }

  .accordion-button {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: ${(props) =>
      props.disabled === true ? "#e0e0e0" : "#fff"};
    height: 36px;
  }

  .accordion-button:not(.collapsed) {
    background-color: #dcdcdc;
    box-shadow: none;
    color: black;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
`;

export const ContainerDuo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
