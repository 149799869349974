import { Link } from "react-router-dom";
import { ContainerDropdown, DropdownList } from "./styled";

function DropdownMenu() {
  return (
    <ContainerDropdown role="menu" className="dropdown-menu" >
      <DropdownList>
        <Link role="menuitem" to={"/ecossistema"}>
          Organizações/Iniciativas registradas
        </Link>
        <Link role="menuitem" to={"/grafico"}>
          Gráficos
        </Link>
        <Link role="menuitem" to={"/rede"}>
          Rede
        </Link>
      </DropdownList>
    </ContainerDropdown>
  );
}

export default DropdownMenu;
