import React, { useContext, useEffect, useState } from "react";
import {
  BodyList,
  Body,
  Container,
  ContainerFilter,
  ContainerInputs,
  ContainerTextEcosystem,
  CustomTabs,
  Input,
  InputFilterActor,
  InputFilterInitiative,
  Text,
  TextInput,
  Title,
  TitleFilter,
  TitleList,
  ContainerAccordion,
} from "./styled";
import EcossistemaBackground from "../../assets/img/background-ecossistema.jpg";
import Banner from "../../components/Banner";
import Accordion from "react-bootstrap/Accordion";

import Tab from "react-bootstrap/Tab";
import { getAtor, getIniciativa } from "../../api/apiProjeto/apiProjeto";
import { LoadingContext } from "../../context/LoadingContext";

function Ecosystem() {
  const [activeTab, setActiveTab] = useState("iniciativas");
  const optionsCausa = ["Agricultura urbana", "Assistência social", "Ativismo, mobilização política e voluntariado", "Conservação e preservação ambiental", "Consumo consciente", "Cultura e arte", "Desenvolvimento comunitário", "Desenvolvimento urbano", "Direitos da pessoa idosa", "Direitos das crianças e adolescentes", "Direitos dos animais", "Direitos dos imigrantes e refugiados", "Educação", "Esporte e recreação", "Gênero", "Gestão de águas e saneamento", "Gestão de resíduos e reciclagem", "Inclusão de pessoas com deficiência", "População em situação de rua", "Questões Raciais e Étnicas", "Saúde", "Segurança", "Segurança Alimentar e Nutricional", "Trabalho e renda", "Transporte e mobilidade", "Uso de tecnologia para mudança social"];
  const optionsPublicoAlvo = ["Adultos", "Animais Domésticos", "Comunidades", "Crianças e Adolescentes", "Famílias", "Fundação", "Fauna", "Flora", "Governo", "Idosos", "Jovens", "Mulheres", "Pessoas com deficiência", "Redes"];
  const optionsPublicoAlvoAtor = ["Associações", "Coletivos informais", "Cooperativas", "Empreendedor pessoa física", "Empresa com missão social", "Empresa privada", "Fundações", "Governo", "Movimentos Sociais", "Redes (Suporte)"];

  const [iniciativas, setIniciativas] = useState([]);
  const [atores, setAtores] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterType, setFilterType] = useState(null);

  const { setIsLoading } = useContext(LoadingContext);

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  useEffect(() => {
    setIsLoading(true)
    getIniciativa()
      .then((response) => {
        const iniciativasFiltradas = response.data.filter((iniciativa) => iniciativa.verificado === true && iniciativa.autorizoDivulgacao);
        setIniciativas(iniciativasFiltradas);
      })
      .catch((error) => console.error("Erro ao carregar iniciativas:", error));

    getAtor()
      .then((response) => {
        const atoresFiltrados = response.data.filter((ator) => ator.verificado === true && ator.autorizoDivulgacao);
        setAtores(atoresFiltrados);
      })
      .catch((error) => console.error("Erro ao carregar atores:", error));
    setIsLoading(false)
  }, []);

  useEffect(() => {
    updateFilteredData();
  }, [activeTab, iniciativas, atores, filterType]);

  const updateFilteredData = () => {
    switch (activeTab) {
      case "iniciativas":
        switch (filterType) {
          case "Causa":
            setFilteredData(optionsCausa);
            break;
          case "Público Alvo":
            setFilteredData(optionsPublicoAlvo);
            break;
          case "A-Z":
            orderDataAlphabetically(iniciativas);
            break;
          default:
            setFilteredData(iniciativas);
        }
        break;
      case "atores":
        switch (filterType) {
          case "Público Alvo":
            setFilteredData(optionsPublicoAlvo);
            break;
          case "A-Z":
            orderDataAlphabetically(atores);
            break;
          default:
            setFilteredData(atores);
        }
        break;
      default:
        break;
    }
  };

  const filterDataByCausa = () => {
    setFilterType("Causa");
  };

  const filterDataByPublicoAlvo = () => {
    setFilterType("Público Alvo");
  };

  const orderDataAlphabetically = () => {
    setFilterType("A-Z");
  };


  return (
    <Container>
      <Banner
        title={
          "Conheça as iniciativas de inovação social, os atores de suporte e as arenas de políticas públicas que já participam do OBISP"
        }
        background={EcossistemaBackground}
      />
      <Body>
        <ContainerTextEcosystem>
          <Title>Ecossistema</Title>
          <Text>
          Conectada com uma base cartográfica, a plataforma tem a capacidade de gerar múltiplas leituras espaciais do conjunto de relações que compõem a rede do Ecossistema de Inovações Sociais de Petrópolis, conectando suas expressões espaciais, temporais e informacionais, além de apresentar visualmente a rede, com os perfis das iniciativas, onde estão localizadas, causas em que atuam e regiões onde se inserem, promovendo também o cruzamento desses dados
            <br /><br />
            O trabalho do OBISP inclui o mapeamento das iniciativas de inovação social de Petrópolis, assim como o acompanhamento das iniciativas que já temos mapeadas e entrevistadas. Buscamos também mapear mais atores que apoiam as inovações sociais, sejam universidades, centros de pesquisa/estudo, empresas, ONGs, que possam vir a construir coletivamente a plataforma para fortalecimento do Ecossistema. Todos os dados recolhidos colaboram para as análises e estudos do OBISP a partir da identificação dos principais problemas públicos da cidade, mas também das formas como os atores sociais se articulam para enfrentá-los nas arenas onde esses problemas públicos se dão.
            <br /><br />
          </Text>
        </ContainerTextEcosystem>
        <TitleList>Lista de iniciativas e atores de suporte</TitleList>
        <BodyList>
          <CustomTabs
            activeTab={activeTab}
            onSelect={handleSelect}
            defaultActiveKey="iniciativas"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="iniciativas" title="Iniciativas Sociais">
              <ContainerFilter>
                <TitleFilter>Filtro de busca por:</TitleFilter>
                <ContainerInputs>
                  <Input>
                    <InputFilterInitiative
                      type="radio"
                      value="Causa"
                      name="gender"
                      onChange={filterDataByCausa}
                    />
                    <TextInput>Causa</TextInput>
                  </Input>
                  <Input>
                    <InputFilterInitiative
                      type="radio"
                      value="Público Alvo"
                      name="gender"
                      onChange={filterDataByPublicoAlvo}
                    />
                    <TextInput>Público Alvo</TextInput>
                  </Input>
                  <Input>
                    <InputFilterInitiative
                      type="radio"
                      value="A-Z"
                      name="gender"
                      onChange={orderDataAlphabetically}
                    />
                    <TextInput>A-Z</TextInput>
                  </Input>
                </ContainerInputs>
              </ContainerFilter>
              <ContainerAccordion>
                <Accordion>
                  {filterType === "Causa" && optionsCausa.map((causa, index) => (
                    <Accordion.Item key={index} eventKey={`${filterType}-${index}`}>
                      <Accordion.Header>{causa}</Accordion.Header>
                      <Accordion.Body>
                        {iniciativas
                          .filter((iniciativa) =>
                            iniciativa &&
                            (iniciativa.causa1 === causa || iniciativa.causa2 === causa || iniciativa.causa3 === causa)
                          )
                          .map((filteredItem, filteredIndex) => (
                            <div key={filteredIndex}>
                              <strong>{filteredItem.nome}</strong>
                            </div>
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                  {filterType === "Público Alvo" && optionsPublicoAlvo.map((publico, index) => (
                    <Accordion.Item key={index} eventKey={`${filterType}-${index}`}>
                      <Accordion.Header>{publico}</Accordion.Header>
                      <Accordion.Body>
                        {iniciativas
                          .filter((iniciativa) =>
                            iniciativa &&
                            (iniciativa.categoriaPublicoIniciativa1 === publico || iniciativa.categoriaPublicoIniciativa2 === publico || iniciativa.categoriaPublicoIniciativa3 === publico)
                          )
                          .map((filteredItem, filteredIndex) => (
                            <div key={filteredIndex}>
                              <strong>{filteredItem.nome}</strong>
                            </div>
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                  {filterType === "A-Z" && iniciativas
                    .sort((a, b) => a.nome.localeCompare(b.nome))
                    .map((filteredItem, filteredIndex) => (
                      <Accordion.Item key={filteredIndex} eventKey={`${filterType}-${filteredIndex}`}>
                        <Accordion.Header>{filteredItem.nome}</Accordion.Header>
                        <Accordion.Body>
                          <strong>{filteredItem.nome}</strong>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </ContainerAccordion>
            </Tab>
            <Tab eventKey="atores" title="Atores de Suporte">
              <ContainerFilter>
                <TitleFilter>Filtro de busca por:</TitleFilter>
                <ContainerInputs>
                  <Input>
                    <InputFilterActor
                      type="radio"
                      value="Público Alvo"
                      name="gender"
                      onChange={() => filterDataByPublicoAlvo("Público Alvo")}
                    />
                    <TextInput>Público Alvo</TextInput>
                  </Input>
                  <Input>
                    <InputFilterActor type="radio" value="A-Z" name="gender" onChange={() => orderDataAlphabetically(atores)} />
                    <TextInput>A-Z</TextInput>
                  </Input>
                </ContainerInputs>
              </ContainerFilter>
              <ContainerAccordion>
                <Accordion>
                  {filterType === "Público Alvo" &&
                    optionsPublicoAlvoAtor.map((publico, index) => (
                      <Accordion.Item key={index} eventKey={`${filterType}-${index}`}>
                        <Accordion.Header>{publico}</Accordion.Header>
                        <Accordion.Body>
                          {atores
                            .filter(
                              (ator) =>
                                ator.responseFrequencia &&
                                ator.responseFrequencia.some(
                                  (freq) =>
                                    freq.descricaoFrequenciaPublico === publico
                                )
                            )
                            .map((filteredItem, filteredIndex) => (
                              <div key={filteredIndex}>
                                <strong>{filteredItem.nome}</strong>
                              </div>
                            ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  {filterType === "A-Z" &&
                    atores
                      .sort((a, b) => a.nome.localeCompare(b.nome))
                      .map((filteredItem, filteredIndex) => (
                        <Accordion.Item
                          key={filteredIndex}
                          eventKey={`${filterType}-${filteredIndex}`}
                        >
                          <Accordion.Header>{filteredItem.nome}</Accordion.Header>
                          <Accordion.Body>
                            <strong>{filteredItem.nome}</strong>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                </Accordion>
              </ContainerAccordion>
            </Tab>
          </CustomTabs>

        </BodyList>
      </Body>
    </Container>
  );
}

export default Ecosystem;
