import React from "react";
import { PiAsteriskThin } from "react-icons/pi";
import { Input, Label } from "./styled";
import { useForm } from "react-hook-form";

function InputText({
  label,
  id,
  type,
  require,
  width,
  isTelefone,
  placeholder,
  totalNumber,
  isCep,
  disabled,
  // value,
  // mask,
  // maskChar,
  registro,
}) {
  const { setValue, register } = useForm();

  const formatPhoneNumber = (value, totalNumber) => {
    const numericValue = value.replace(/\D/g, "");

    const limitedValue = numericValue.slice(0, totalNumber);

    let formattedValue = "";
    for (let i = 0; i < limitedValue.length; i++) {
      if (i === 0) {
        formattedValue += `(${limitedValue[i]}`;
      } else if (i === 2) {
        formattedValue += `) ${limitedValue[i]}`;
      } else if (i === totalNumber - 4) {
        formattedValue += `-${limitedValue[i]}`;
      } else {
        formattedValue += limitedValue[i];
      }
    }

    return formattedValue;
  };
  const formatCep = (value) => {
    const numericValue = value.replace(/\D/g, "");

    const limitedValue = numericValue.slice(0, 8);

    let formattedValue = "";
    for (let i = 0; i < limitedValue.length; i++) {
      if (i === 2) {
        formattedValue += `.${limitedValue[i]}`;
      } else if (i === 5) {
        formattedValue += `-${limitedValue[i]}`;
      } else {
        formattedValue += limitedValue[i];
      }
    }

    return formattedValue;
  };

  const handleChange = (event, totalNumber) => {
    const inputValue = event.target.value;
    const formattedValue = isTelefone ? formatPhoneNumber(inputValue, totalNumber) : inputValue;
    const formattedCepValue = isCep ? formatCep(inputValue) : inputValue;
    console.log ("event",event)
    register(id);

    setValue(id, inputValue.replace(/\D/g, ""));

    event.target.value = isTelefone ? formattedValue : formattedCepValue;
  };

  return (
    <Label htmlFor={id} require={String.toString(require)} width={width}>
      {require ? (
        <>
          {label} <PiAsteriskThin />
        </>
      ) : (
        label
      )}
      <Input
        id={id}
        type={type}
        required={require}
        placeholder={placeholder}
        onChange={(e) => handleChange(e, totalNumber)}
        disabled={disabled}
        // mask={mask}
        // maskChar={maskChar}
        // value={value}
        {...registro}
      />
    </Label>
  );
}

export default InputText;
