import styled from "styled-components";
import theme from "../../global/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #000000e5;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LoadingLogoImg = styled.img`
  width: 170px;
  height: 120px;
  position: fixed;
  left: 50%;
  transform: translate(-50%,0);
`;

export const BodyHome = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
`;
export const ContainerQuantity = styled.div`
  width: 80%;
  display: flex;
  border: 1px solid ${theme.colors.azulMarinho};
  border-radius: 20px;
  padding: 3% 3%;
  align-items: center;
  justify-content: space-between;
  margin: 8% 0;
`;
export const TextQuantity = styled.p`
  width: 70%;
  text-align: justify;
  margin: 0;
  color: #424242;
  font-weight: 600;
  font-size: 18px;
`;
export const ContainerQuantityIndicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const TitleQuantity = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.color};
`;
