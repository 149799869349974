import styled from "styled-components";
import { IoEnterOutline } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";
import theme from "../../global/colors";

export const HeaderTag = styled.header`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  z-index: 9999;
  background: rgb(6,19,36);
  background: linear-gradient(20deg, rgba(6,19,36,1) 66%, rgba(11,30,40,1) 80%, rgba(21,51,43,1) 91%, rgba(34,94,50,1) 100%);
`;

export const HeaderInfos = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 80px;
`;

export const ContainerLogo = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 150px;
  border-radius: 50px;
  cursor: pointer;
`;
export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-self: self-top;
  gap: 50px;
  color: white;
`;

export const SocialNetworks = styled.div`
  display: flex;
  gap: 5px;

  a {
    text-decoration: none;
    color: white;
  }
`;

export const IconEnter = styled(IoEnterOutline)`
  width: 33px;
  height: 25px;
  cursor: pointer;
  color: ${theme.colors.verdeClaro}
`;

export const IconInstagram = styled(FaInstagram)`
  width: 17px;
  height: 27px;
`;
