import styled from "styled-components";
import theme from "../../global/colors";
import { IoIosConstruct } from "react-icons/io";

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #E0E0E0;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3%;
  min-width: 80%;
  max-width: 80%;
`;

export const Title = styled.h1`
  margin-bottom: 3%;
  font-size: 30px;
  font-weight: 600;
  border-left: 2px solid ${theme.colors.verdeEscuro};
  padding-left: 15px;
  color: #424242;
`;
export const Text = styled.p`

`;

export const Body = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
`;

export const IconConstruct = styled(IoIosConstruct)`
  width: 50px;
  height: 50px;
`;

export const NetworkContainer = styled.div`
  width: 80%;
  height: 800px;
  border-radius: 8px;
  border: 1px solid rgb(189, 189, 189);
  margin-bottom: 3rem;
  overflow: hidden; 
`

export const SubtitlesContainer = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 1px solid rgb(189, 189, 189);
  padding: 15px;
  padding-bottom: 15px;
  display: flex;
  gap: 15px;
`

export const GraphSubtitle = styled.div`
  height: 40px;
  width: fit-content;
  background-color: ${theme.colors.lightGrey};
  border-radius: 6px;
  border: 0.5px solid ${theme.colors.borderGrey};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:0 10px 0 2px;
  gap: 5px;
`

export const SubtitleColor = styled.div`
  margin-left: 5px;
  height: 25px;
  width: 25px;
  background-color: ${(props) => props.color};
  border-radius: 6px;
  border: 0.5px solid ${theme.colors.borderGrey};
`

export const SubtitleText = styled.p`
  font-weight: 400;
  text-align: center;
  margin: 0;
`