import styled from "styled-components";
import AtoresBackground from "../../assets/img/background-atorsuporte.jpg";
import theme from "../../global/colors";
import Alert from "react-bootstrap/Alert";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 80vw;
  margin-bottom: 60px;
`;

export const ContainerBackgroundBanner = styled.div`
  background-image: url(${AtoresBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-color: #616161;
  background-blend-mode: multiply;
  width: 100%;
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
`;

export const ContainerBannerInfos = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const TitleBanner = styled.h1``;

export const SuportTitle = styled.h1`
  display: flex;
  align-items: center;
  width: 80%;
  align-items: start;
  color: ${theme.colors.verdeEscuro};
  border-bottom: 1px solid ${theme.colors.verdeEscuro};
  padding: 2% 0;
  font-size: 38px;
  font-weight: 400;
`;
export const ContainerIndiceForm = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: center;
  align-items: start;
  color: #424242;
  padding: 2% 0;
`;
export const IndiceForm = styled.p`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  color: ${theme.colors.verdeEscuro};
  border-left: solid 2px ${theme.colors.verdeEscuro};
  padding-left: 14px;
`;
export const DataContainerForm = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  flex-direction: column;
  padding: 6px;
`;
export const ContainerInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const ContainerButtonForm = styled.div`
  display: flex;
  justify-content: end;
`;

export const ButtonSubmit = styled.button`
  border: none;
  width: 20%;
  height: 40px;
  background-color: ${theme.colors.green};
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  margin-left: 40px;
  &:hover {
    background-color: #008c61;
  }
`;

export const ButtonReset = styled.button`
  border: solid 1px ${theme.colors.borderGrey};
  width: 20%;
  height: 40px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: inherit;
  border-radius: 4px;
  color: ${theme.colors.black};

  &:hover {
    background-color: ${theme.colors.primary};
    color: #fff;
  }
`;
export const TitleForm = styled.h1`
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
`;

export const PerformanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30%;
  .alert-heading {
    padding-bottom: 20px;
    border-bottom: 1px solid #198754;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
  }
`;