import React, { useContext } from "react";
import {
  Container,
  ContainerNotLogged,
  ContainerPanel,
  NotLoggedLogo,
  NotLoggedText,
} from "./styled";
import TabMenuAdmPanel from "../../components/TabMenuAdmPanel";
import { LoadingContext } from "../../context/LoadingContext";
import Logo from "../../assets/img/Logo2-removebg-preview.png";
import { AllowButton } from "../../components/RegistersFilterAdmPanelInitiative/styled";
import { useNavigate } from "react-router-dom";

function AdmPanel() {
  const { isLogged } = useContext(LoadingContext);
  const navigate = useNavigate();

  return (
    <Container>
      <ContainerPanel>
        {isLogged ? (
          <TabMenuAdmPanel />
        ) : (
          <ContainerNotLogged>
            <NotLoggedLogo src={Logo} alt="Logo" />
            <NotLoggedText>Não foi possível acessar a página!</NotLoggedText>
            <AllowButton onClick={() => navigate("/")}>
              Voltar para página inicial
            </AllowButton>
          </ContainerNotLogged>
        )}
      </ContainerPanel>
    </Container>
  );
}

export default AdmPanel;
