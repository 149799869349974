import React from 'react'
import { ContainerBackgroundBanner, ContainerBannerInfos, ContainerRegister, SubtitleBanner, TitleBanner } from './styled'
import RegisterHome from '../RegisterHome'

function BannerHome() {
  return (
    <ContainerBackgroundBanner>
        <ContainerBannerInfos>
          <TitleBanner>
          Inovação Social em rede
          </TitleBanner>
          <SubtitleBanner>
          Conheça e participe do Ecossistema de Inovações Sociais de Petrópolis
          </SubtitleBanner>
        </ContainerBannerInfos>
        <ContainerRegister>
          <RegisterHome
            title={
              "Promove soluções para resolver os problemas públicos da cidade"
            }
            button={"Sou uma iniciativa de inovação social"}
            onClick={"/cadastro-iniciativa-social"}
          />
          <RegisterHome
            title={
              "Apoia iniciativas que promovem inovação social na cidade"
            }
            button={"Sou um ator de suporte"}
            onClick={"/cadastro-ator-suporte"}
          />
        </ContainerRegister>
      </ContainerBackgroundBanner>
  )
}

export default BannerHome