import React from "react";
import {
  HeaderTag,
  HeaderInfos,
  Logo,
  ContainerLogo,
  IconEnter,
  IconInstagram,
  SocialNetworks,
  Icons,
} from "./styled";
import SubHeader from "../SubHeader";
import LogoConectaIniciativas from "../../assets/img/Logo2.png";

import { Link, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  return (
    <HeaderTag>
      <HeaderInfos>
        <ContainerLogo>
          <Logo
            tabIndex={0}
            alt="Logo do site conecta iniciativas"
            src={LogoConectaIniciativas}
            onClick={() => {
              navigate("/");
            }}
          />
        </ContainerLogo>
        <Icons>
          <SocialNetworks>
            <IconInstagram />
            <Link
              aria-label="instagram do eispetropolis"
              to={"https://instagram.com/eispetropolis?igshid=MTk0NTkyODZkYg=="}
              target="_blank"
            >
              @eispetropolis
            </Link>
          </SocialNetworks>
        </Icons>
      </HeaderInfos>
      <SubHeader />
    </HeaderTag>
  );
}

export default Header;
