import styled from "styled-components";
import theme from "../../global/colors";

export const InfoRegistersContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  gap: 80px;
  width: 100%;
  border: solid 1px #dee2e6;
  border-radius: 4px;
  padding: 20px;
  margin: 10px 0;
  & div{
    width: 50%;
  }
  & hr{
    color: #dee2e6;
    opacity: 1;
  }
`;

export const AutorizationButtonsCRUD = styled.div`
  display: flex;
  justify-content: start;
`;

export const AutorizationButtons = styled.div`
  display: flex;
  justify-content: end;
`;

export const AllowButton = styled.button`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  border: none;
  height: 2rem;
  background-color: ${theme.colors.green};
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  margin-left: 20px;
  &:hover {
    background-color: #008c61;
  }
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px grey;
  padding: 20px;
  height: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 20px;
  background-color: grey;
  border-radius: 4px;
  color: white;

  &:hover {
    background-color: #52565b;
    color: #fff;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px grey;
  padding: 20px;
  height: 2rem;
  margin-left: 20px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
  background-color: ${theme.colors.borderGrey};
  color: #fff;

  &:hover {
    background-color: grey;
    color: #fff;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 1px red;
  padding: 20px;
  height: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
  background-color: red;
  color: #fff;

  &:hover {
    background-color: #d20000;
    color: #fff;
  }
`;

export const DeleteButtonListaCompleta = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 1px red;
  padding: 20px;
  margin-left: 20px;
  height: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  background-color: inherit;
  border-radius: 4px;
  background-color: red;
  color: #fff;

  &:hover {
    background-color: #d20000;
    color: #fff;
  }
`;


