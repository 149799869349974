import React from "react";
import {
  BodyAbout,
  Container,
  ContainerTeam,
  ContainerTextAbout,
  ContainerTitleAbout,
  TitleTeam,
  SubTitleTeam,
  ContainerImage,
  ContainerImageInicial,
  Description
} from "./styled";

import Financiers from "../../components/Financiers";
import Banner from "../../components/Banner";
import MemberTeam from "../../components/MemberTeam";

import PetropolisBackground from "../../assets/img/background-petropolis.jpg";
import FotoGustavo from "../../assets/img/fotoGustavo.png";
import FotoReginaldo from "../../assets/img/fotoReginaldo.png";
import FotoGopala from "../../assets/img/fotoGopala.png";
import FotoArtur from "../../assets/img/fotoArtur.png";
import FotoJessika from "../../assets/img/fotoJessika.png";
import FotoCarla from "../../assets/img/fotoCarlinha.jpg";
import BackgroundAbout from '../../assets/img/background-quemsomos.jpg'

function AboutUs() {
  return (
    <Container>
      <Banner
        title={"Conheça o Observatório e a nossa equipe"}
        background={BackgroundAbout}
      />
      <BodyAbout>
        <ContainerTitleAbout>
          O <b>Observatório de Inovação Social de Petrópolis (OBISP)</b> é uma plataforma de pesquisa colaborativa que visa promover a visibilidade, a interação, o fortalecimento e a aprendizagem coletiva no Ecossistema de Inovação Social de Petrópolis, ampliando a incidência sobre os problemas públicos da cidade.

          Reunimos pesquisadores, atores sociais, organizações e iniciativas interessados em reforçar as dinâmicas de interação, experimentação e participação a fim de promover inovações sociais para a democracia e para a sustentabilidade.
        </ContainerTitleAbout>
        <ContainerTextAbout>
          <p>
            Produto do projeto de pesquisa “Cartografia do Ecossistema de Inovações Sociais de Petrópolis”, iniciado em agosto de 2023, o OBISP surge a partir da parceria e do intercâmbio com o Observatório de Inovação Social de Florianópolis (OBISF), tendo por base sua experiência prévia e metodologia, e está inserido no projeto "Rede colaborativa para favorecer a inovação social na ciência e nas políticas públicas: articulando pesquisa, ensino e extensão", que teve início em 2024, financiado pela CAPES PROEXT. Buscamos reconhecer e publicizar o conhecimento sobre as iniciativas de inovação social e os atores que as apoiam, a fim de compreender suas histórias, lutas, dilemas, dinâmicas de interação, redes de articulação e os efeitos que geram em resposta aos problemas públicos da cidade.

          </p>

          <p>
            O projeto foi fruto de uma parceria entre o Instituto de Pesquisa e Planejamento Urbano e Regional da Universidade Federal do Rio de Janeiro (Ippur/UFRJ), o Instituto Philippe Guédon de Gestão Participativa (IPG), o Núcleo de Estudos em Pesquisa e Extensão em Inovações Sociais na Esfera Pública, da Universidade do Estado de Santa Catarina (NISP/UDESC), o Serratec - Parque Tecnológico da Região Serrana e a Fundação Carlos Chagas Filho de Amparo à Pesquisa do Estado do Rio de Janeiro (Faperj), financiadora da pesquisa.

          </p>

          <p>
            A partir do conhecimento gerado e do reconhecimento dos atores sociais que atuam frente aos problemas públicos é possível pensar formas de dinamizar as interações para co-produzir soluções para a cidade, fortalecendo a participação social e contribuindo para o desenvolvimento social, a democracia, a sustentabilidade e a gestão participativa.
          </p>
        </ContainerTextAbout>
      </BodyAbout>
      <ContainerTeam>
        <TitleTeam>Equipe</TitleTeam>
        <Description>
          A equipe tem como objetivo mapear a rede que forma o Ecossistema
          de Inovação Social (EIS) no município de Petrópolis-RJ, permitindo
          identificar as causas sociais em que atuam e as redes de relações.
        </Description>
        <ContainerImageInicial>
          <MemberTeam
            image={FotoGustavo}
            name={"Gustavo Costa de Souza "}
            office={"Coordenador"}
            lattes={"https://lattes.cnpq.br/0152465744578116"}
          />
          <MemberTeam
            image={FotoCarla}
            name={"Carla Magno"}
            office={"Pesquisadora"}
            lattes={"http://lattes.cnpq.br/5736015258783561"}
          />
        </ContainerImageInicial>
        <ContainerImage>
          <MemberTeam
            image={FotoReginaldo}
            name={"Reginaldo Braga Silva Junior"}
            office={"Pesquisador"}
            year={"Ago/23 a Jul/24"}
            lattes={"https://lattes.cnpq.br/9914909653385649"}
          />
          <MemberTeam
            image={FotoGopala}
            name={"Gopala Miron"}
            office={"Pesquisadora"}
            year={"Ago/23 a Dez/23"}
            lattes={"https://lattes.cnpq.br/6392301765502168"}
          />
          <MemberTeam
            image={FotoArtur}
            name={"Artur Porto"}
            office={"Bolsista"}
            year={"Ago/23 a Set/24"}
            lattes={"https://lattes.cnpq.br/4162222790027570"}
          />
          <MemberTeam
            image={FotoJessika}
            name={"Jéssika Fernandes"}
            office={"Bolsista"}
            year={"Ago/23 a Set/24"}
            lattes={"https://lattes.cnpq.br/4556915534487093"}
          />
        </ContainerImage>
        <SubTitleTeam>Equipe de Desenvolvedores do Programa Serratec de Residência em TIC</SubTitleTeam>
        <ContainerImage>
          <MemberTeam
            name={"Lucas Bonafé"}
            office={"Desenvolvedor Full-Stack"}
            linkedin={"www.linkedin.com/in/lucas-bonafé"}
          />
          <MemberTeam
            name={"Khezac Khalleb"}
            office={"Desenvolvedor Full-Stack"}
            linkedin={"https://www.linkedin.com/in/khezac/"}
          />
          <MemberTeam
            name={"Felipe de Moura"}
            office={"Desenvolvedor Front-end"}
            linkedin={"https://www.linkedin.com/in/felipe-de-moura/"}
          />
          <MemberTeam
            name={"Letícia Bussinger"}
            office={"Desenvolvedora Front-end"}
            linkedin={"https://www.linkedin.com/in/leticia-bussinger/"}
          />
          <MemberTeam
            name={"Sidney Sábio"}
            office={"Desenvolvedor Front-end"}
            linkedin={"https://www.linkedin.com/in/sidney-s%C3%A1bio-b2671a20b/"}
          />
          <MemberTeam
            name={"Thiago Bellato"}
            office={"Desenvolvedor Back-end"}
            linkedin={"https://www.linkedin.com/in/thiagobellato/3"}
          />
          <MemberTeam
            name={"Gustavo Pinheiro"}
            office={"Desenvolvedor Back-end"}
            linkedin={"https://www.linkedin.com/in/gustavopinheiro-/"}
          />
          <MemberTeam
            name={"Eduardo Pacheco"}
            office={"Desenvolvedor Back-end"}
            linkedin={"https://www.linkedin.com/in/eduardopachecocarvalho/"}
          />
        </ContainerImage>
      </ContainerTeam>
      <BodyAbout>
        <Financiers />
      </BodyAbout>
    </Container>
  );
}

export default AboutUs;
