import React, { useContext, useState } from 'react'
import { BodyContact, Logo, BreadCrumb, Container, ContainerPassword, Forms, HideEye, Input, Label, LoginButton, ModalOverlay, ShowEye, StyledAlert, Title } from './styled';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { login } from '../../api/apiProjeto/apiProjeto';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Alert from "react-bootstrap/Alert";
import { FaArrowLeft } from 'react-icons/fa'; 
import Button from 'react-bootstrap/Button';
import LogoConectaIniciativas from "../../assets/img/Logo1.png";
import { LoadingContext } from '../../context/LoadingContext';


function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const { setIsLoading, setIsLogged } = useContext(LoadingContext);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  }
  const handleLogin = async (data) => {
    setIsLoading(true)
    try {
      const response = await login(data);
      console.log(response);
      const token = response.data.token;
      localStorage.setItem('token', token);
      console.log('Token obtido:', token);
      setIsLogged(true);
      sessionStorage.setItem("usuarioLogado", token.slice(1,3))
      navigate("/painel-administrador")
      setShow(false);
    } catch (error) {
      console.error('Erro no login', error);
      setShow(true);
    }
    setIsLoading(false)
  };
  return (
    <Container>
     <BreadCrumb>
      <Breadcrumb.Item href="/">
        <FaArrowLeft style={{ marginRight: '10px', height: '100%' }} /> Voltar
      </Breadcrumb.Item>
    </BreadCrumb>
      <BodyContact>
        
        
        <Forms onSubmit={handleSubmit(handleLogin)}>
        <Logo
            tabIndex={0}
            alt="Logo do site conecta iniciativas"
            src={LogoConectaIniciativas}
            onClick={() => {
              navigate("/");
            }}
          />
          <Title>Bem-vindo!</Title>
          <Label htmlFor="email">
            Email
            <Input
              id="email"
              type="text"
              {...register("email")}
              onChange={(e) => e.target.value = e.target.value.trim()}
            />
          </Label>
          <Label htmlFor="senha">
            Senha
            <ContainerPassword>
              <Input
                id="senha"
                type={showPassword ? "text" : "password"}
                {...register("senha")}
                onChange={(e) => e.target.value = e.target.value.trim()}
              />
              {showPassword ? <ShowEye onClick={handlePassword} /> : <HideEye onClick={handlePassword} />}
            </ContainerPassword>
          </Label>
          <LoginButton type="submit">Entrar</LoginButton>
        </Forms>
        <ModalOverlay style={{ display: show ? 'flex' : 'none' }}>
          <StyledAlert show={show} variant="danger">
            <Alert.Heading>Credenciais inválidas!</Alert.Heading>
            <p>
              Verifique seu email e senha.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => {
                setShow(false);
                window.scrollTo(0, 0);
              }} variant="outline-danger">
                Fechar
              </Button>
            </div>
          </StyledAlert>
        </ModalOverlay>
      </BodyContact>
    </Container>
  )
}
export default Login;