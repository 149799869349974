import styled from "styled-components";
import theme from "../../global/colors";
import InputMask from "react-input-mask";

export const Input = styled.input`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: ${(props)=>(props.disabled === true ? "#e0e0e0" : "#fff")};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;
  

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 189, 189, 0.577468487394958);
  }
`;
export const Label = styled.label`
    color: ${(props)=>(props.require === true ? `${theme.colors.red}` : `${theme.colors.black}`)};
    font-size: 14px;
    font-weight: 400;
    width: ${(props)=>(props.width || "100%")};
    margin: 6px 0;
`;
