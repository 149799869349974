import styled from "styled-components";
import PetropolisBackground from "../../assets/img/background-petropolis.jpg";

export const ContainerBackgroundBanner = styled.div`
  background-image: url(${PetropolisBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-color: #616161;
  background-blend-mode: multiply;
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
`;

export const ContainerBannerInfos = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const TitleBanner = styled.h1`
  font-weight: 600;
`;

export const SubtitleBanner = styled.h3`
  font-size: 20px;
  font-weight: 400; 
`;


export const ContainerRegister = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5%;
  gap: 150px;
`;

