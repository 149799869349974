import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Input, Label, Option } from "./styled";

function YearSelectForm({ label, registro }) {
  const anosDisponiveis = [
    "",
    ...Array.from({ length: new Date().getFullYear() - 1899 }, (_, index) => 1900 + index)
  ];

  const { setValue, watch } = useForm();

  const selectedYear = watch(label);

  useEffect(() => {
    if (selectedYear) {
      setValue(label, selectedYear);
    }
  }, [selectedYear, setValue, label]);

  return (
    <Label>
      {label}
      <Input {...registro}>
        {anosDisponiveis.map((ano) => (
          <Option key={ano} value={ano}>
            {ano || "Selecione..."}
          </Option>
        ))}
      </Input>
    </Label>
  );
}

export default YearSelectForm;
