import { ButtonRegister, ContainerRegister, TitleRegister } from "./styled";
import { useNavigate } from 'react-router-dom';

function RegisterHome({title, button, onClick}) {
  const navigate = useNavigate();

  const navigateClick = () =>{
    navigate(onClick)
  }

  return (
    <ContainerRegister>
      <TitleRegister>
        {title}
      </TitleRegister>
      <ButtonRegister onClick={navigateClick} title={button}>{button}</ButtonRegister>
    </ContainerRegister>
  );
}

export default RegisterHome;
