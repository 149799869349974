import styled from "styled-components";
import theme from "../../global/colors";
import { PiAddressBookFill } from "react-icons/pi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import Alert from "react-bootstrap/Alert";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const BodyContact = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
`;
export const Title = styled.h1`
  font-size: 35px;
  font-weight: 600;
`;

export const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 400;
`;

export const ContainerForms = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  margin: 5% 0 10% 0;
  padding: 3%;
`;

export const Information = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 20px;
`;
export const TitleInformation = styled.h1`
  font-size: 25px;
  align-self: center;
`;
export const Contacts = styled.div`
  height: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: self-start;
  gap: 25px;
`;
export const ContactType = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;
export const IconEmail = styled(PiAddressBookFill)`
  width: 30px;
  height: 30px;
`;
export const IconMarker = styled(FaMapMarkerAlt)`
  width: 30px;
  height: 30px;
`;
export const IconInstagram = styled(FaInstagram)`
  width: 30px;
  height: 30px;
`;
export const Forms = styled.form`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 55%;
  padding: 2%;
`;
export const ButtonSend = styled.button`
  border: none;
  background-color: ${theme.colors.verdeHover};
  margin-top: 20px;
  padding: 2%;
  border-radius: 0.25rem;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  &:hover{
    background-color: ${theme.colors.verdeSecondaryHover};
  }
`;
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30%;
  .alert-heading {
    padding-bottom: 20px;
    border-bottom: 1px solid #198754;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
  }
`;

export const InputText = styled.input`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: ${(props) =>
    props.disabled === true ? "#e0e0e0" : "#fff"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 189, 189, 0.577468487394958);
  }
`;
export const Label = styled.label`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  margin: 6px 0;
`;

export const InputTextArea = styled.textarea`
   border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 150px; 
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 189, 189, 0.577468487394958);
  }
`;