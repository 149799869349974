import React, {useState, useEffect, useContext} from 'react'
import { Container, Title, ContainerText, Text, NetworkContainer, GraphSubtitle, SubtitleColor, SubtitleText, SubtitlesContainer } from './styled'
import RedeBackground from '../../assets/img/background-rede.jpg'
import Banner from '../../components/Banner'
import Graph from "react-graph-vis";
import { getAtor, getIniciativa } from '../../api/apiProjeto/apiProjeto';
import { LoadingContext } from '../../context/LoadingContext';
import theme from "../../global/colors";

function Rede() {
  const [marcadores, setMarcador] = useState([]);
  const [atores, setAtores] = useState([]);
  const [iniciativas, setIniciativas] = useState([]);
  const [netLigacoes, setNetLigacoes] = useState([]);

  const { setIsLoading } = useContext(LoadingContext);

  const buscaAtor = async () => {
    setIsLoading(true)
    try {
      const {data} = await getAtor();
      setAtores(data);
    } catch (error) {
      console.error("Erro ao obter atores:", error);
    }
    setIsLoading(false)
  };

  const buscaIniciativas = async () => {
    setIsLoading(true)
    try {
      const {data} = await getIniciativa();
      setIniciativas(data);
    } catch (error) {
      console.error("Erro ao obter atores:", error);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    buscaAtor();
    buscaIniciativas();
  }, []);

  useEffect(() => {
    iteracaoLista(atores,iniciativas);
  },[iniciativas])
  
  let id = 0; // ID usado para identificação dos marcadores do gráfico de network
  const iteracaoLista = (listaAtores,listaIniciativas) =>{
    let listaMarcadores = [];

    for(let i = 0; i < listaAtores.length; i++){
      let newObj;
      //  Transforma o ator de suporte que esta no indice(i) em um objeto que o gráfico interpreta como marcador
      if(listaAtores[i].sigla !== '') {
        newObj = {
          id : id,
          label : listaAtores[i].sigla,
          title : listaAtores[i].nome,
          group: 'atores'
        }
      } else {
        newObj = {
          id : id,
          label : listaAtores[i].nome,
          title : listaAtores[i].nome,
          group: 'atores'
        }
      }

      listaMarcadores.push(newObj)
      id++
    }

    for(let i = 0; i < listaIniciativas.length; i++){
      let newObj;
      //  Transforma a iniciativa que esta no indice(i) em um objeto que o gráfico interpreta como marcador
      if(listaIniciativas[i].sigla !== '') {
        newObj = {
          id : id,
          label : listaIniciativas[i].sigla,
          title : listaIniciativas[i].nome,
          group: 'iniciativas'
        }
      } else {
        newObj = {
          id : id,
          label : listaIniciativas[i].nome,
          title : listaIniciativas[i].nome,
          group: 'iniciativas'
        }
      }
      listaMarcadores.push(newObj)
      id++
    }

    setMarcador(listaMarcadores)
    criaLigacao(atores, listaMarcadores);
  }

  const criaLigacao = (listaAtores, marcadores) => {
    let ligacoes = [];
    
    //  Percorre a lista de atores de suporte
    for(let i = 0; i < listaAtores.length ; i++) {
      //  Percorre a lista de todos os marcadores
      for(let j = 0; j < marcadores.length; j++) {
        //  Valida se o marcador do indice(j) (Nome da iniciativa) condiz com alguma iniciativa do ator no indice(i)
        if(marcadores[j].title.toLowerCase().trim() === listaAtores[i].iniciativa1.toLowerCase().trim() || 
          marcadores[j].title.toLowerCase().trim() === listaAtores[i].iniciativa2.toLowerCase().trim() || 
          marcadores[j].title.toLowerCase().trim() === listaAtores[i].iniciativa3.toLowerCase().trim() 
        ) {
          //  Percorre a lista de todos os marcadores captando o ID do marcador ao qual a ligação pertence
          for(let k = 0; k < marcadores.length; k++){
            if(marcadores[k].title === listaAtores[i].nome) {
              ligacoes.push({
                from: marcadores[k].id,
                to: marcadores[j].id
              })
            }
          }
        }
      }

    }
    
    setNetLigacoes(ligacoes);
  }

  // Informações dos componentes da network
  const graph = {
    // Marcadores exibidos
    nodes: marcadores,
    // Ligações dos marcadores
    edges: netLigacoes,
  };

  // Configurações adicionais da network
  const options = {
    groups: {
      iniciativas: {
        color: {background: `${theme.colors.vine}`, border: `${theme.colors.vine}`},
      },
      atores: {
        color: {background: `${theme.colors.verdeEscuro}`, border: `${theme.colors.verdeEscuro}`}
      }
    },
    nodes: {
      shape: 'dot',
    },
    layout: {
      hierarchical: false,
    },
    edges: {
      smooth: true,
      chosen: true,
      width: 2,
      arrows: {
        to : {
          enabled: false,
          type: 'arrow'
        }
    }
  },
    height: "700px",
  };

  return (
    <Container>
      <Banner title={"Observe a rede do Ecossistema de Inovação Social de Petrópolis"} background={RedeBackground} />
        <ContainerText>
            <Title>Rede</Title>
            <Text>
            Os dados sistematizados relativos ao Observatório sobre iniciativas de inovação social e aos atores de suporte estão disponíveis na rede, permitindo o acesso categorizado pela conexão entre cada iniciativa e ator de suporte.
            <br /> <br />
            Clicando em um dos ícones, é possível observar as interações entre este ator social e os outros atores do ecossistema. As cores diferenciam interações com atores de suporte e interações com outras iniciativas de inovação social. As conexões foram indicadas pelos próprios atores cadastrados na plataforma.
            </Text>
        </ContainerText>
        <NetworkContainer>
          <SubtitlesContainer>
            <GraphSubtitle>
              <SubtitleColor 
                color={`${theme.colors.verdeEscuro}`}
              />
              <SubtitleText>
                Atores de Suporte
              </SubtitleText>
            </GraphSubtitle>
            <GraphSubtitle>
              <SubtitleColor 
                color={`${theme.colors.vine}`}
              />
              <SubtitleText>
                Iniciativas
              </SubtitleText>
            </GraphSubtitle>
          </SubtitlesContainer>
          <Graph
            key={Math.random()}
            graph={graph}
            options={options}
          />
        </NetworkContainer>
    </Container>
  )
}

export default Rede