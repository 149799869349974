import styled from "styled-components";
import theme from "../../global/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BodyProblems = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
`;

export const ContainerTextProblems = styled.div`
  display: flex;
  padding-top: 3%;
  flex-direction: column;
`;

export const Title = styled.h1`
  margin-bottom: 5%;
  font-size: 30px;
  border-left: 2px solid ${theme.colors.verdeHover};
  padding-left: 15px;
  color: #424242;
`;
export const Text = styled.p`
  margin: 0;
  text-align: justify;
`;

export const TitleList = styled.h1`
  margin-bottom: 3%;
  margin-top: 8%;
  font-size: 20px;
  align-self: flex-start;
  border-left: 2px solid ${theme.colors.verdeHover};
  padding-left: 15px;
  color: #424242;
`;

export const ContainerListProblems = styled.div`
  width: 100%;
  padding: 2%;
  border: 1px solid ${theme.colors.borderGrey};
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

export const ListProblems = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.borderGrey};
`;

export const InputList = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.borderGrey};
  border-width: 1px 1px 0 1px;
  padding: 10px;
`;
