import axios from "axios";

const url = axios.create({
  baseURL: "https://maps.googleapis.com/maps/api/geocode/json",
  params: {
      key: "AIzaSyBdsUcesv6py4ob-2rOr7F4DKQkfs4-9kA"
  }
})

export async function getCoordenates (logradouro, bairro, localidade, uf){
    const endereco = logradouro + ',' + bairro + ',' + localidade + ',' + uf;
    try {
        if(endereco){
            const response = await url.get('', {
            params: { address: endereco }
            })

            if (response.status === 200 && response.data.results.length > 0) {
                console.log(response.data.results)
                return response.data.results[0].geometry.location;
            } else {
                alert(`Falha ao obter o endereço: -` + response.status);
                return null;
            }
    }
    } catch (error) {
        alert('Erro na requisição do Geocoding: ' + error);
    }
}