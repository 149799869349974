import React from "react";
import { Input, Label } from "./styled";

function TextAreaForm({ label, id, width, height, placeholder, registro }) {
  return (
    <Label htmlFor={id} width={width}>
      {label}
      <Input placeholder={placeholder} id={id} type="text" height={height} {...registro} />
    </Label>
  );
}

export default TextAreaForm;
