import React from "react";
import { ImageMember, MemberInfos, NameMember, OfficeMember, YearMember } from "./styled";
import { Link } from "react-router-dom";

function MemberTeam({ image, name, office, lattes, linkedin, year }) {
  return (
    <MemberInfos>
      <ImageMember aria-hidden="true" src={image} />
      <NameMember>{name}</NameMember>
      <OfficeMember>{office}</OfficeMember>
      <YearMember>{year}</YearMember>
      { lattes == null ? 
        <Link
          aria-label={"Currículo no Linkedin de " + name}
          to={linkedin}
          target="_blank"
        >
        LinkedIn
        </Link>
      :
      <Link
        aria-label={"Currículo no LATTES de " + name}
        to={lattes}
        target="_blank"
      >
        LATTES
      </Link>
      }
    </MemberInfos>
  );
}

export default MemberTeam;
