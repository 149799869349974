import styled from "styled-components";
import theme from "../../global/colors";
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Alert from "react-bootstrap/Alert";
import PetropolisBackground from "../../assets/img/background-petropolis.jpg";

export const Container = styled.div`
  background-image: 
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${PetropolisBackground});
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const BodyContact = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h1`
  font-size: 35px;
  font-weight: 600;
  margin-top: 4%;
`;

export const Logo = styled.img`
  width: 250px;
  border-radius: 20%;
  cursor: pointer;
`;

export const Subtitle = styled.h3`
  font-size: 14px;
  font-weight: 400;
`;

export const Forms = styled.form`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  margin-top: 7%;
  padding: 3%;
`;

export const LoginButton = styled.button`
  background-color: ${theme.colors.verdeHover};
  width: 100%;
  border: none;
  border-radius: 10px;
  margin-top: 7%;
  padding: 1% 0 1% 0;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
   &:hover{
    background-color: ${theme.colors.verdeSecondaryHover};
  }
`;

export const Input = styled.input`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: ${(props) =>
    props.disabled === true ? "#e0e0e0" : "#fff"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
  }
`;
export const Label = styled.label`
  color: ${(props) =>
    props.require == true ? `${theme.colors.red}` : `${theme.colors.black}`};
  font-size: 14px;
  font-weight: 400;
  width: ${(props) => props.width || "100%"};
  /* min-width: 147px; */
  margin: 6px 0;
`;

export const ShowEye = styled(LuEye)`
  width: 25px;
  height: 25px;
`;

export const HideEye = styled(LuEyeOff)`
  width: 25px;
  height: 25px;
`;

export const ContainerPassword = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-right: 0.9rem;
  border: 0.5px solid ${theme.colors.borderGrey};
  border-radius: 0.25rem;
  background-color: #fff;
  & input{
    border: none;
  }
`;

export const BreadCrumb = styled(Breadcrumb)`
  width: 80%;
  margin-bottom: 3px;
  a {
    color: ${theme.colors.verdeClaro};
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30%;
  .alert-heading {
    padding-bottom: 20px;
    border-bottom: 1px solid #dc3545;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
  }
`;
