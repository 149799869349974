import styled from "styled-components";
import theme from "../../global/colors";
import Tabs from "react-bootstrap/Tabs";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5%;
`;

export const ContainerTextEcosystem = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3%;
`;

export const Title = styled.h1`
  margin-bottom: 3%;
  font-size: 30px;
  border-left: 2px solid ${theme.colors.azulHover};
  padding-left: 15px;
  color: #424242;
`;
export const Text = styled.p`
  margin: 0;
  text-align: justify;
`;

export const TitleList = styled.h1`
  margin-bottom: 3%;
  margin-top: 8%;
  font-size: 20px;
  align-self: flex-start;
  border-left: 2px solid ${theme.colors.verdeHover};
  padding-left: 15px;
  color: #424242;
`;

export const BodyList = styled.div`
  width: 100%;
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  width: 100%;
  padding: 3% 0 3% 0;
  margin-top: 1%;
`;

export const InputFilterInitiative = styled.input.attrs({ type: "radio" })`
  width: 20px;
  height: 20px;

  &:checked {
    accent-color: ${theme.colors.azulHover};
  }
`;

export const InputFilterActor = styled.input.attrs({ type: "radio" })`
  width: 20px;
  height: 20px;

  &:checked {
    accent-color: #78913d;
  }
`;
export const ContainerInputs = styled.div`
  display: flex;
  padding: 0 8% 0 8%;
  gap: 30px;
`;
export const TitleFilter = styled.h1`
  font-size: 18px;
  font-weight: bold;
`;
export const Input = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const TextInput = styled.p`
  margin: 0;
  font-size: 16px;
`;

export const CustomTabs = styled(Tabs)`
  .nav-link.active {
    background-color: ${(props) =>
      props.activeTab === "iniciativas"
        ? `${theme.colors.azulHover}`
        : `${theme.colors.verdeHover}`};
    color: #fff;
    border: none;
  }
  .nav-link {
    color: #000;
  }
`;

export const ContainerAccordion = styled.div`
  width: 100%;
  margin-top: 4px;

  .accordion-button.collapsed {
   border-radius: 10px !important;
   
}
  
  .accordion-button{
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  
  .accordion-button:not(.collapsed) {
    background-color: #dcdcdc;
    box-shadow: none;
    color: black;    
  }
  .accordion-button:focus {
    box-shadow: none;
    
  }
`;
