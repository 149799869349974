import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import theme from "../../global/colors";

export const ContainerSubHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  background: rgb(5,17,33);
  background: linear-gradient(180deg, rgba(5,17,33,1) 9%, rgba(255,255,255,0) 87%);
  transition: all 0.3s ease;

  a {
    text-decoration: none;
    padding: 14px;
    color: #fff;
    font-family: "Merriweather Sans", sans-serif;
    font-weight: 500;
  }
`;


export const ArrowDown = styled(SlArrowDown)`
  margin-left: 5px;
  width: 10px;
`;

export const EcossystemDropdown = styled.div`
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 500;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
`;
