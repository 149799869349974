import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowDown, ContainerSubHeader, EcossystemDropdown } from "./styled";
import DropdownMenu from "../DropDown";
import { FaHome } from "react-icons/fa"; // Importa o ícone de "home"

function SubHeader() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        // Clique fora do SubHeader, fecha o DropdownMenu
        setVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      // Remove o event listener ao desmontar o componente
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const handleClick = () => {
    setVisible(!isVisible);
  };

  return (
    <ContainerSubHeader ref={containerRef}>
      <Link to="/">
        <FaHome size={20} />
      </Link>
      <Link to={"/sobre-nos"}>Quem somos</Link>
      <EcossystemDropdown
        tabIndex={0}
        role="button"
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        Ecossistema
        {(isDropdownVisible || isVisible) && <DropdownMenu />}
        <ArrowDown aria-hidden="true" />
      </EcossystemDropdown>
      <Link to={"/problemas-publicos"}>Problemas públicos</Link>
      <Link to={"/metodologia"}>Metodologia</Link>
      <Link to={"/contato"}>Contato</Link>
    </ContainerSubHeader>
  );
}

export default SubHeader;
