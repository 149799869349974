import React, { useContext, useEffect, useState } from 'react'
import { Body, Container, ContainerChart, ContainerTabs, ContainerText, CustomTabs, Text, Title } from './styled';
import GraficoBackground from '../../assets/img/background-graficos.jpg'
import Banner from '../../components/Banner'
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import Tab from 'react-bootstrap/Tab';
import { getAtor, getIniciativa } from '../../api/apiProjeto/apiProjeto';
import { LoadingContext } from '../../context/LoadingContext';

function Graphic() {
  const [activeTab, setActiveTab] = useState("iniciativas");
  const optionsTipoOrganização = ["Associação", "Coletivo Informal", "Cooperativa", "Centro de Pesquisa", "Empreendedor Pessoa Física", "Empresa com missão social e ambiental", "Fundação", "Movimento Social", "Organização sem fins lucrativos", "Plataforma ou aplicativo", "Programa de empresa RSC", "Programa Governamental", "Programa OSC", "Programa Universitário", "Rede"];
  const optionsCausa = ["Agricultura urbana", "Assistência social", "Ativismo, mobilização política e voluntariado", "Conservação e preservação ambiental", "Consumo consciente", "Cultura e arte", "Desenvolvimento comunitário", "Desenvolvimento urbano", "Direitos da pessoa idosa", "Direitos das crianças e adolescentes", "Direitos dos animais", "Direitos dos imigrantes e refugiados", "Educação", "Esporte e recreação", "Gênero", "Gestão de águas e saneamento", "Gestão de resíduos e reciclagem", "Inclusão de pessoas com deficiência", "População em situação de rua", "Questões Raciais e Étnicas", "Saúde", "Segurança", "Segurança Alimentar e Nutricional", "Trabalho e renda", "Transporte e mobilidade", "Uso de tecnologia para mudança social"];
  const optionsPublicoAlvo = ["Adultos", "Animais Domésticos", "Comunidades", "Crianças e Adolescentes", "Famílias", "Fundação", "Fauna", "Flora", "Governo", "Idosos", "Jovens", "Mulheres", "Pessoas com deficiência", "Redes"];

  const optionsPublicoAlvoAtor = ["Associações", "Coletivos informais", "Cooperativas", "Empreendedor pessoa física", "Empresa com missão social", "Empresa privada", "Fundações", "Governo", "Movimentos Sociais", "Redes (Suporte)"];

  const optionsAbrangenciaAtor = ["Local","Regional","Nacional","Internacional"];

  const [iniciativas, setIniciativas] = useState([]);
  const [atores, setAtores] = useState([]);

  const { setIsLoading } = useContext(LoadingContext);

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  useEffect(() => {
    setIsLoading(true)
    
    getIniciativa()
      .then((response) => {
        const iniciativasFiltradas = response.data.filter((iniciativa) => iniciativa.verificado === true && iniciativa.autorizoDivulgacao);
        setIniciativas(iniciativasFiltradas);
      })
      .catch((error) => console.error("Erro ao carregar iniciativas:", error));

    getAtor()
      .then((response) => {
        const atoresFiltrados = response.data.filter((ator) => ator.verificado === true && ator.autorizoDivulgacao);
        setAtores(atoresFiltrados);
      })
      .catch((error) => console.error("Erro ao carregar atores:", error));

    setIsLoading(false)
  }, []);



  const countChartData = (field, options, data) => {
    const chartData = options.map((option) => {
      const count = data.reduce((accumulator, item) => {
        for (let i = 1; i <= 3; i++) {
          const fieldToCheck = `${field}${i}`;
          if (item[fieldToCheck] === option) {
            accumulator += 1;
          }
        }
        return accumulator;
      }, 0);
      return { name: option, y: count };
    });
    return chartData;
  };

  const countChartDataTipoOrgIniciativa = (field, options, data) => {
    const chartData = options.map((option) => {
      const count = data.reduce((accumulator, item) => {
        if (item[field] === option) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);
      return { name: option, y: count };
    });
    return chartData;
  };

  const countChartDataAtor = (field, options, data) => {
    // ChartData serão os objetos mostrados no gráfico
    const chartData = options.map((option) => {
      // Count é o numero de ocorrências de determinada validação
      const count = data.reduce((accumulator, item) => {
        let responseField = '';
        //  Valida se o item que atual na lista possui o atributo do parâmetro "field"
        if (item[field]) {
          responseField = field;
        }
        else if (item.responseFrequencia && item.responseFrequencia.length && item.responseFrequencia[0][field]) {
          responseField = 'responseFrequencia';
        } else if (item.responseOrg && item.responseOrg.length && item.responseOrg[0][field]) {
          responseField = 'responseOrg';
        } else if (item.responseCateg && item.responseCateg.length && item.responseCateg[0][field]) {
          responseField = 'responseCateg';
        }

        //  Após a validação percorremos os atributos do campo passado por parâmetro
        for (let i = 0; i < item[responseField]?.length; i++) {
          const fieldToCheck = item[responseField][i][field];
          //  Valida o atributo atual de acordo com a option atual e incrementa o acumulador
          if (fieldToCheck === option) {
            accumulator += 1;
          }
        }
        return accumulator;
      }, 0);
      //  Objeto q será inserido em chartData
      return { name: option, y: count };
    });
    return chartData;
  };

  const pieChartDataTipoOrganizacaoIniciativa = countChartDataTipoOrgIniciativa('tipoOrganizacao', optionsTipoOrganização, iniciativas);
  const chartOptionsTipoOrganizacaoIniciativa = {
    title: {
      text: 'Tipo de Organização',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Total',
        data: pieChartDataTipoOrganizacaoIniciativa,
      },
    ],
  };

  const pieChartDataCausa = countChartData('causa', optionsCausa, iniciativas);
  const chartOptionsCausa = {
    title: {
      text: 'Causa',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Total',
        data: pieChartDataCausa,
      },
    ],
  };

  const pieChartDataPublicoAlvo = countChartData('categoriaPublicoIniciativa', optionsPublicoAlvo, iniciativas);
  const chartOptionsPublicoAlvo = {
    title: {
      text: 'Público Alvo',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Total',
        data: pieChartDataPublicoAlvo,
      },
    ],
  };

  const pieChartDataTipoOrganizacaoAtor = countChartDataAtor('descricaoOrganizacaoAtor', optionsTipoOrganização, atores);
  const chartOptionsTipoOrganizacaoAtor = {
    title: {
      text: 'Tipo de Organização',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Total',
        data: pieChartDataTipoOrganizacaoAtor,
      },
    ],
  };

  const pieChartDataPublicoAlvoAtor = countChartDataAtor('descricaoFrequenciaPublico', optionsPublicoAlvoAtor, atores);
  const chartOptionsPublicoAlvoAtor = {
    title: {
      text: 'Público Alvo',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Total',
        data: pieChartDataPublicoAlvoAtor,
      },
    ],
  };

  const pieChartDataAbrangenciaAtor = countChartDataTipoOrgIniciativa('abrangencia', optionsAbrangenciaAtor, atores);
  const chartOptionsAbrangenciaAtor= {
    title: {
      text: 'Escala de abrangência',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Total',
        data: pieChartDataAbrangenciaAtor,
      },
    ],
  };

  return (
    <Container>
      <Banner title={"Dados consolidados do OBISP expressos em gráficos"} background={GraficoBackground} />
      <Body>
        <ContainerText>
          <Title>Gráficos</Title>
          <Text>
            Os dados sistematizados relativos ao Observatório sobre iniciativas de inovação social e aos atores de suporte estão disponíveis em gráficos, permitindo o acesso categorizado por tipo de organização, causa e público alvo.
          </Text>
        </ContainerText>
        <ContainerTabs>
          <CustomTabs
            activeTab={activeTab}
            onSelect={handleSelect}
            defaultActiveKey="iniciativas"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="iniciativas" title="Iniciativas Sociais">
              <ContainerChart>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptionsTipoOrganizacaoIniciativa}
                />
              </ContainerChart>
              <ContainerChart>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptionsCausa}
                />
              </ContainerChart>
              <ContainerChart>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptionsPublicoAlvo}
                />
              </ContainerChart>
            </Tab>

            <Tab eventKey="atores" title="Atores de Suporte">
              <ContainerChart>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptionsTipoOrganizacaoAtor}
                />
              </ContainerChart>
              {/* <ContainerChart>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptionsCausaAtor}
                />
              </ContainerChart>
                GRAFICO PARA EXIBIR CAUSAS DOS ATORES, SÓ É POSSÍVEL DE IMPLEMENTAR CASO A RELAÇÃO DE ATORES E INICIATIVAS FOR CONSERTADA
              */}
              <ContainerChart>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptionsPublicoAlvoAtor}
                />
              </ContainerChart>
              <ContainerChart>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptionsAbrangenciaAtor}
                />
              </ContainerChart>
            </Tab>
          </CustomTabs>
        </ContainerTabs>
      </Body>
    </Container>
  );
}

export default Graphic