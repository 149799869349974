import styled from "styled-components";

export const ContainerDropdown = styled.div`
  display: block;
  background-color: #f5f5f5;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  transition: all linear 0.3s;
`;
export const DropdownList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: unset;
  margin: unset;

  a {
    font-size: 14px;
    background-color: white;
    color: #424242;
    text-decoration: none;
    padding: 14px;
    transition: all linear 0.3s;
  }
  a:hover {
    background-color: #DCDCDC;
    cursor: pointer;
  }
`;
