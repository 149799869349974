import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home/index.jsx";
import App from "./App.jsx";
import AboutUs from "./pages/AboutUs/index.jsx";
import Ecosystem from "./pages/Ecosystem/index.jsx";
import PublicProblems from "./pages/PublicProblems/index.jsx";
import Annoucement from "./pages/Rede/index.jsx";
import Contact from "./pages/Contact/index.jsx";
import Graphic from "./pages/Graphic/index.jsx";
import InitiativeRegistration from "./pages/InitiativeRegistration/index.jsx";
import Methodology from "./pages/Methodology/index.jsx";
import SuportRegistration from "./pages/SuportRegistration/index.jsx";
import AdmPanel from "./pages/AdmPanel/index.jsx";
import Login from "./pages/Login/index.jsx";
import { LoadingContextProvider } from "./context/LoadingContext.jsx";
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/sobre-nos",
        element: <AboutUs />,
      },
      {
        path: "/ecossistema",
        element: <Ecosystem />,
      },
      {
        path: "/problemas-publicos",
        element: <PublicProblems/>,
      },
      {
        path: "/rede",
        element: <Annoucement />,
      },
      {
        path: "/contato",
        element: <Contact />,
      },
      {
        path: "/grafico",
        element: <Graphic />,
      },
      {
        path: "/metodologia",
        element: <Methodology />,
      },
      {
        path: "/cadastro-iniciativa-social",
        element: <InitiativeRegistration />,
      },
      {
        path: "/cadastro-ator-suporte",
        element: <SuportRegistration/>,
      },
      {
        path: "/painel-administrador",
        element: <AdmPanel/>,
      },
      {
        path: "/login-administrador",
        element: <Login/>,
      },

    ]
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <StyleSheetManager 
      shouldForwardProp={shouldForwardProp}
      enableVendorPrefixes
    >
      <LoadingContextProvider>
        <RouterProvider router={router} />
      </LoadingContextProvider>
    </StyleSheetManager>
  </React.StrictMode>
);

function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
      return isPropValid(propName);
  }
  return true;
}
