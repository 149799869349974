import styled from "styled-components";
import theme from "../../global/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContainerTitleAbout = styled.div`
  font-size: 20px;
  margin-bottom: 50px;
  margin-top: 30px;
  text-align: center;
`;

export const ContainerTextAbout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  text-align: justify;
  
  border-top: 1px solid ${theme.colors.azulHover};
  padding-top: 3%;
`;

export const BodyAbout = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
`;

export const ContainerTeam = styled.div`
  background-color: ${theme.colors.verdeSecondaryHover};
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5%;
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
`;

export const TitleTeam = styled.h1`
  border-left: 3px solid ${theme.colors.azulHover};
  font-size: 35px;
  color: #fff;
  margin-bottom: 3%;
`;

export const SubTitleTeam = styled.h1`
  margin-top: 5%;
  font-size: 30px;
  color: #fff;
  margin-bottom: 0%;
`;

export const Description = styled.h1`
  font-size: 20px;
  color: #fff;
`;

export const ContainerImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5%;
  margin-right: 5%;
`;

export const ContainerImageInicial = styled.div`
  width: 100%;
  justify-content: center;
  gap: 20%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5%;
`;
