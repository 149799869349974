import {
  InfoRegistersContainer,
  AutorizationButtons,
  AllowButton,
  DeleteButton,
  EditButton,
  StatusButton,
  CancelButton,
  DeleteButtonListaCompleta,
  AutorizationButtonsCRUD
} from "./styled";
import { Accordion } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import {
  getIniciativa,
  putIniciativaById,
  putIniciativaByIdAdmin,
} from "../../api/apiProjeto/apiProjeto";
import { Modal, Button } from "react-bootstrap";
import { ContainerAccordion } from "../AccordionAdmPanel/styled";
import { AiFillSave, AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { LoadingContext } from "../../context/LoadingContext";

function RegistersFilterAdmPanelInitiative({ tipoLista }) {
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedIniciativaId, setSelectedIniciativaId] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // cont modo de edição
  const [formData, setFormData] = useState({});
  const { setIsLoading } = useContext(LoadingContext);

  const handleShowDeleteModal = (iniciativaId) => {
    setSelectedIniciativaId(iniciativaId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedIniciativaId(null);
    setShowDeleteModal(false);
  };

  const handleGetIniciativas = async () => {
    setIsLoading(true);
    try {
      const response = await getIniciativa();
      setData((prevData) => response.data);
    } catch (error) {
      console.error("Erro ao buscar dados da API", error);
    }
    setIsLoading(false)
  };

  useEffect(() => {
    handleGetIniciativas();
  }, []);

  const handleAllowButtonClick = async (iniciativaId) => {
    try {
      await putIniciativaById(iniciativaId, {
        verificado: true,
        ativo: "mapeado",
      });

      setData((prevData) =>
        prevData.filter((item) => item.iniciativaId !== iniciativaId)
      );
    } catch (error) {
      console.error("Erro ao permitir", error);
    }
  };

  const handleDeleteButtonClick = async (iniciativaId) => {
    try {
      console.log("Deleting Iniciativa with id:", iniciativaId);
      await putIniciativaById(iniciativaId, {
        verificado: false,
        ativo: "excluido",
      });

      setData((prevData) =>
        prevData.filter((item) => item.iniciativaId !== iniciativaId)
      );

      handleCloseDeleteModal();

      setTimeout(() => {
        alert("Removido com sucesso!");
      }, 100);
    } catch (error) {
      console.error("Erro ao deletar", error);
    }
  };

  const handleCancelar = () => {
    setIsEditing(false);
  };

  const handleEditClick = (item) => {
    setFormData(item);
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      await putIniciativaByIdAdmin(formData.iniciativaId, formData);

      setIsEditing(false);;
      alert("Atualizado com sucesso!");
      handleGetIniciativas();
    } catch (error) {
      console.error("Erro ao atualizar", error.response ? error.response.data : error.message);
    }
  };
  

  const handleStatusButtonButtonClick = async (iniciativaId, status) => {
    try {
      await putIniciativaById(iniciativaId, {
        verificado: true,
        ativo: status,
      });

      setData((prevData) =>
        prevData.map((item) =>
          item.iniciativaId === iniciativaId ? { ...item, ativo: status } : item
        )
      );
    } catch (error) {
      console.error("Erro ao atualizar status", error);
    }
  };

  return (

    <>
      {data.sort((a, b) => a.nome.localeCompare(b.nome)).map((item, index) => (
        <ContainerAccordion tipostatus={item.ativo} height={"60px"} key={index}>
          {tipoLista === "cadastroIniciativa" && item.verificado == false && (
            <Accordion defaultActiveKey={`subItem-${index}0`}>
              <Accordion.Item
                key={`subItem-${index}`}
                eventKey={`subItem-${index}`}
              >
                <Accordion.Header> {item.nome}</Accordion.Header>
                <Accordion.Body>
                  <InfoRegistersContainer>
                    <div>
              
                  {isEditing ? (
                    <div>
                      <div>
                        <label>Nome da organização:</label>
                        <input
                          type="text"
                          name="nome"
                          value={formData.nome}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Sigla:</label>
                        <input
                          type="text"
                          name="sigla"
                          value={formData.sigla}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Data de Início da iniciativa:</label>
                        <input
                          type="date"
                          name="dataInicio"
                          value={formData.dataInicio}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Status da organização:</label>
                        <input
                          type="text"
                          name="ativo"
                          value={formData.ativo}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Telefone:</label>
                        <input
                          type="text"
                          name="telefone"
                          value={formData.telefone}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Telefone Comercial:</label>
                        <input
                          type="text"
                          name="telComercial"
                          value={formData.telComercial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Celular:</label>
                        <input
                          type="text"
                          name="celular"
                          value={formData.celular}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Rede Social 1:</label>
                        <input
                          type="text"
                          name="redeSocial"
                          value={formData.redeSocial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Rede Social 2:</label>
                        <input
                          type="text"
                          name="redeSocial2"
                          value={formData.redeSocial2}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Rede Social 3:</label>
                        <input
                          type="text"
                          name="redeSocial3"
                          value={formData.redeSocial3}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Site:</label>
                        <input
                          type="text"
                          name="site"
                          value={formData.site}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Email:</label>
                        <input
                          type="text"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Representante:</label>
                        <input
                          type="text"
                          name="representante"
                          value={formData.representante}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Representante Comercial:</label>
                        <input
                          type="text"
                          name="repComercial"
                          value={formData.repComercial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>CEP:</label>
                        <input
                          type="text"
                          name="cep"
                          value={formData.cep}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Logradouro:</label>
                        <input
                          type="text"
                          name="logradouro"
                          value={formData.logradouro}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Número:</label>
                        <input
                          type="text"
                          name="numero"
                          value={formData.numero}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Complemento:</label>
                        <input
                          type="text"
                          name="complemento"
                          value={formData.complemento}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Complemento 2:</label>
                        <input
                          type="text"
                          name="complemento2"
                          value={formData.complemento2}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Bairro:</label>
                        <input
                          type="text"
                          name="bairro"
                          value={formData.bairro}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Cidade:</label>
                        <input
                          type="text"
                          name="localidade"
                          value={formData.localidade}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>UF:</label>
                        <input
                          type="text"
                          name="uf"
                          value={formData.uf}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>País:</label>
                        <input
                          type="text"
                          name="pais"
                          value={formData.pais}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Promotor da Iniciativa:</label>
                        <input
                          type="text"
                          name="promotorIniciativa"
                          value={formData.promotorIniciativa}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Problemas Públicos:</label>
                        <input
                          type="text"
                          name="problemasPublicos"
                          value={formData.problemasPublicos}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Solução para os Problemas:</label>
                        <input
                          type="text"
                          name="solucaoProblemas"
                          value={formData.solucaoProblemas}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Incidência:</label>
                        <input
                          type="text"
                          name="incidencia"
                          value={formData.incidencia}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Apoio Financeiro:</label>
                        <input
                          type="text"
                          name="apoioFinaceiro"
                          value={formData.apoioFinaceiro}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Suporte Financeiro:</label>
                        <input
                          type="text"
                          name="suporteFinanceiro"
                          value={formData.suporteFinanceiro}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Outro Apoio:</label>
                        <input
                          type="text"
                          name="outroApoio"
                          value={formData.outroApoio}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Outro Suporte:</label>
                        <input
                          type="text"
                          name="outroSuporte"
                          value={formData.outroSuporte}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Indicação da Iniciativa:</label>
                        <input
                          type="text"
                          name="indicacaoIniciativa"
                          value={formData.indicacaoIniciativa}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Organização:</label>
                        <input
                          type="text"
                          name="organizacao"
                          value={formData.organizacao}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Autorização para Divulgação:</label>
                        <input
                          type="checkbox"
                          name="autorizoDivulgacao"
                          checked={formData.autorizoDivulgacao}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              autorizoDivulgacao: e.target.checked,
                            }))
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p>Nome da organização: {item.nome}</p>
                      <hr />
                      <p>Sigla: {item.sigla}</p>
                      <hr />
                      <p>Data de Início da iniciativa: {item.dataInicio}</p>
                      <hr />
                      <p>Status da organização: {item.ativo ?? "teste"}</p>
                      <hr />
                      <p>Telefone: {item.telefone}</p>
                      <hr />
                      <p>Telefone Comercial: {item.telComercial}</p>
                      <hr />
                      <p>Celular: {item.celular}</p>
                      <hr />
                      <p>Rede Social 1: {item.redeSocial}</p>
                      <hr />
                      <p>Rede Social 2: {item.redeSocial2}</p>
                      <hr />
                      <p>Rede Social 3: {item.redeSocial3}</p>
                      <hr />
                      <p>Site: {item.site}</p>
                      <hr />
                      <p>Email: {item.email}</p>
                      <hr />
                      <p>Representante: {item.representante}</p>
                      <hr />
                      <p>Representante Comercial: {item.repComercial}</p>
                      <hr />
                      <p>CEP: {item.cep}</p>
                      <hr />
                      <p>Logradouro: {item.logradouro}</p>
                      <hr />
                      <p>Número: {item.numero}</p>
                      <hr />
                      <p>Complemento: {item.complemento}</p>
                      <hr />
                      <p>Complemento 2: {item.complemento2}</p>
                      <hr />
                      <p>Bairro: {item.bairro}</p>
                      <hr />
                      <p>Cidade: {item.localidade}</p>
                      <hr />
                      <p>UF: {item.uf}</p>
                      <hr />
                      <p>País: {item.pais}</p>
                      <hr />
                      <p>Promotor da Iniciativa: {item.promotorIniciativa}</p>
                      <hr />
                      <p>Problemas Públicos: {item.problemasPublicos}</p>
                      <hr />
                      <p>Solução para os Problemas: {item.solucaoProblemas}</p>
                      <hr />
                      <p>Incidência: {item.incidencia}</p>
                      <hr />
                      <p>Apoio Financeiro: {item.apoioFinaceiro}</p>
                      <hr />
                      <p>Suporte Financeiro: {item.suporteFinanceiro}</p>
                      <hr />
                      <p>Outro Apoio: {item.outroApoio}</p>
                      <hr />
                      <p>Outro Suporte: {item.outroSuporte}</p>
                      <hr />
                      <p>Indicação da Iniciativa: {item.indicacaoIniciativa}</p>
                      <hr />
                      <p>Organização: {item.organizacao}</p>
                      <hr />
                      <p>
                        Autorização para Divulgação:{" "}
                        {item.autorizoDivulgacao ? " Aceito" : " Recusado"}
                      </p>
                      <hr />
                    </div>
                  )}
                </div>
                      </InfoRegistersContainer>
                      <AutorizationButtonsCRUD>
                        { isEditing ?     
                          <AutorizationButtonsCRUD>
                          <AllowButton onClick={() => handleSaveClick()}>
                            SALVAR <AiFillSave /> 
                          </AllowButton>
                          <CancelButton onClick={() => handleCancelar()}>
                            CANCELAR
                          </CancelButton>
                        </AutorizationButtonsCRUD>
                        :
                        <>
                          <DeleteButton
                            onClick={() => handleShowDeleteModal(item.iniciativaId)}
                          >
                            DELETAR 
                          </DeleteButton>

                          <EditButton
                            onClick={() => handleEditClick(item)}
                          >
                            EDITAR
                          </EditButton>

                          <AllowButton
                            onClick={() => handleAllowButtonClick(item.iniciativaId)}
                          >
                            ACEITAR
                          </AllowButton>
                        </>
                        }
                      </AutorizationButtonsCRUD>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {tipoLista === "listaCompleta" && item.verificado !== false && (
                <Accordion defaultActiveKey={`subItem-${index}0`}>
                  <Accordion.Item
                    key={`subItem-${index}`}
                    eventKey={`subItem-${index}`}
                  >
                    <Accordion.Header>{item.nome}</Accordion.Header>
                    <Accordion.Body>
                    <InfoRegistersContainer>
                    <div>
              
                  {isEditing ? (
                    <div>
                      <div>
                        <label>Nome da organização:</label>
                        <input
                          type="text"
                          name="nome"
                          value={formData.nome}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Sigla:</label>
                        <input
                          type="text"
                          name="sigla"
                          value={formData.sigla}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Data de Início da iniciativa:</label>
                        <input
                          type="date"
                          name="dataInicio"
                          value={formData.dataInicio}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Status da organização:</label>
                        <input
                          type="text"
                          name="ativo"
                          value={formData.ativo}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Telefone:</label>
                        <input
                          type="text"
                          name="telefone"
                          value={formData.telefone}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Telefone Comercial:</label>
                        <input
                          type="text"
                          name="telComercial"
                          value={formData.telComercial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Celular:</label>
                        <input
                          type="text"
                          name="celular"
                          value={formData.celular}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Rede Social 1:</label>
                        <input
                          type="text"
                          name="redeSocial"
                          value={formData.redeSocial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Rede Social 2:</label>
                        <input
                          type="text"
                          name="redeSocial2"
                          value={formData.redeSocial2}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Rede Social 3:</label>
                        <input
                          type="text"
                          name="redeSocial3"
                          value={formData.redeSocial3}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Site:</label>
                        <input
                          type="text"
                          name="site"
                          value={formData.site}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Email:</label>
                        <input
                          type="text"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Representante:</label>
                        <input
                          type="text"
                          name="representante"
                          value={formData.representante}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Representante Comercial:</label>
                        <input
                          type="text"
                          name="repComercial"
                          value={formData.repComercial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>CEP:</label>
                        <input
                          type="text"
                          name="cep"
                          value={formData.cep}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Logradouro:</label>
                        <input
                          type="text"
                          name="logradouro"
                          value={formData.logradouro}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Número:</label>
                        <input
                          type="text"
                          name="numero"
                          value={formData.numero}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Complemento:</label>
                        <input
                          type="text"
                          name="complemento"
                          value={formData.complemento}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Complemento 2:</label>
                        <input
                          type="text"
                          name="complemento2"
                          value={formData.complemento2}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Bairro:</label>
                        <input
                          type="text"
                          name="bairro"
                          value={formData.bairro}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Cidade:</label>
                        <input
                          type="text"
                          name="localidade"
                          value={formData.localidade}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>UF:</label>
                        <input
                          type="text"
                          name="uf"
                          value={formData.uf}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>País:</label>
                        <input
                          type="text"
                          name="pais"
                          value={formData.pais}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Promotor da Iniciativa:</label>
                        <input
                          type="text"
                          name="promotorIniciativa"
                          value={formData.promotorIniciativa}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Problemas Públicos:</label>
                        <input
                          type="text"
                          name="problemasPublicos"
                          value={formData.problemasPublicos}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Solução para os Problemas:</label>
                        <input
                          type="text"
                          name="solucaoProblemas"
                          value={formData.solucaoProblemas}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Incidência:</label>
                        <input
                          type="text"
                          name="incidencia"
                          value={formData.incidencia}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Apoio Financeiro:</label>
                        <input
                          type="text"
                          name="apoioFinaceiro"
                          value={formData.apoioFinaceiro}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Suporte Financeiro:</label>
                        <input
                          type="text"
                          name="suporteFinanceiro"
                          value={formData.suporteFinanceiro}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Outro Apoio:</label>
                        <input
                          type="text"
                          name="outroApoio"
                          value={formData.outroApoio}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Outro Suporte:</label>
                        <input
                          type="text"
                          name="outroSuporte"
                          value={formData.outroSuporte}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Indicação da Iniciativa:</label>
                        <input
                          type="text"
                          name="indicacaoIniciativa"
                          value={formData.indicacaoIniciativa}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Organização:</label>
                        <input
                          type="text"
                          name="organizacao"
                          value={formData.organizacao}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Autorização para Divulgação:</label>
                        <input
                          type="checkbox"
                          name="autorizoDivulgacao"
                          checked={formData.autorizoDivulgacao}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              autorizoDivulgacao: e.target.checked,
                            }))
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p>Nome da organização: {item.nome}</p>
                      <hr />
                      <p>Sigla: {item.sigla}</p>
                      <hr />
                      <p>Data de Início da iniciativa: {item.dataInicio}</p>
                      <hr />
                      <p>Status da organização: {item.ativo ?? "teste"}</p>
                      <hr />
                      <p>Telefone: {item.telefone}</p>
                      <hr />
                      <p>Telefone Comercial: {item.telComercial}</p>
                      <hr />
                      <p>Celular: {item.celular}</p>
                      <hr />
                      <p>Rede Social 1: {item.redeSocial}</p>
                      <hr />
                      <p>Rede Social 2: {item.redeSocial2}</p>
                      <hr />
                      <p>Rede Social 3: {item.redeSocial3}</p>
                      <hr />
                      <p>Site: {item.site}</p>
                      <hr />
                      <p>Email: {item.email}</p>
                      <hr />
                      <p>Representante: {item.representante}</p>
                      <hr />
                      <p>Representante Comercial: {item.repComercial}</p>
                      <hr />
                      <p>CEP: {item.cep}</p>
                      <hr />
                      <p>Logradouro: {item.logradouro}</p>
                      <hr />
                      <p>Número: {item.numero}</p>
                      <hr />
                      <p>Complemento: {item.complemento}</p>
                      <hr />
                      <p>Complemento 2: {item.complemento2}</p>
                      <hr />
                      <p>Bairro: {item.bairro}</p>
                      <hr />
                      <p>Cidade: {item.localidade}</p>
                      <hr />
                      <p>UF: {item.uf}</p>
                      <hr />
                      <p>País: {item.pais}</p>
                      <hr />
                      <p>Promotor da Iniciativa: {item.promotorIniciativa}</p>
                      <hr />
                      <p>Problemas Públicos: {item.problemasPublicos}</p>
                      <hr />
                      <p>Solução para os Problemas: {item.solucaoProblemas}</p>
                      <hr />
                      <p>Incidência: {item.incidencia}</p>
                      <hr />
                      <p>Apoio Financeiro: {item.apoioFinaceiro}</p>
                      <hr />
                      <p>Suporte Financeiro: {item.suporteFinanceiro}</p>
                      <hr />
                      <p>Outro Apoio: {item.outroApoio}</p>
                      <hr />
                      <p>Outro Suporte: {item.outroSuporte}</p>
                      <hr />
                      <p>Indicação da Iniciativa: {item.indicacaoIniciativa}</p>
                      <hr />
                      <p>Organização: {item.organizacao}</p>
                      <hr />
                      <p>
                        Autorização para Divulgação:{" "}
                        {item.autorizoDivulgacao ? " Aceito" : " Recusado"}
                      </p>
                      <hr />
                    </div>
                  )}
                </div>
                  </InfoRegistersContainer>
                  
                    {isEditing ? 
                      <>
                      <AutorizationButtonsCRUD>
                        <AllowButton onClick={() => handleSaveClick()}>
                          SALVAR <AiFillSave /> 
                        </AllowButton>
                        <CancelButton onClick={() => handleCancelar()}>
                          Cancelar
                        </CancelButton>
                      </AutorizationButtonsCRUD>
                      
                      <AutorizationButtons>

                        {item.ativo != "excluido" &&
                          <>
                            <StatusButton
                              statusbuttoncolor={"inativo"}
                              onClick={() =>
                                handleStatusButtonButtonClick(
                                  item.iniciativaId,
                                  "inativo"
                                )
                              }
                            >
                              INATIVO
                            </StatusButton>
                            <StatusButton
                              statusbuttoncolor={"mapeado"}
                              onClick={() =>
                                handleStatusButtonButtonClick(
                                  item.iniciativaId,
                                  "mapeado"
                                )
                              }
                            >
                              MAPEADO
                            </StatusButton>
                            <StatusButton
                              statusbuttoncolor={"observado"}
                              onClick={() =>
                                handleStatusButtonButtonClick(
                                  item.iniciativaId,
                                  "observado"
                                )
                              }
                            >
                              OBSERVADO
                            </StatusButton>
                            <StatusButton
                              statusbuttoncolor={"acompanhado"}
                              onClick={() =>
                                handleStatusButtonButtonClick(
                                  item.iniciativaId,
                                  "acompanhado"
                                )
                              }
                            >
                              ACOMPANHADO
                            </StatusButton>
                          </>
                          }
                      </AutorizationButtons>
                      </>
                      :
                      <>
                      <AutorizationButtonsCRUD>
                        <EditButton onClick={() => handleEditClick(item)}>
                          EDITAR <AiFillEdit />
                        </EditButton>
                        <DeleteButtonListaCompleta onClick={() => handleShowDeleteModal(item.iniciativaId)}>
                          DELETAR <AiFillDelete />
                        </DeleteButtonListaCompleta>
                      </AutorizationButtonsCRUD>
                      </>
                    } 
                  
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </ContainerAccordion>
      ))}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Remoção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja remover a iniciativa cadastrado da lista de aprovação?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Não
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteButtonClick(selectedIniciativaId)}
          >
            Sim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RegistersFilterAdmPanelInitiative;
