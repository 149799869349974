import React from 'react'
import { BodyProblems, Container, ContainerListProblems, ContainerTextProblems, InputList, ListProblems, Text, Title, TitleList } from './styled'
import ProblemasBackground from "../../assets/img/problemaspublicos.jpg"
import Banner from '../../components/Banner'

function PublicProblems() {
  const optionsProblemasPublicos = ["Agricultura urbana", "Assistência social", "Ativismo, mobilização política e voluntariado", "Conservação e preservação ambiental", "Consumo consciente", "Cultura e arte", "Desenvolvimento comunitário", "Desenvolvimento urbano", "Direitos da pessoa idosa", "Direitos das crianças e adolescentes", "Direitos dos animais", "Direitos dos imigrantes e refugiados", "Direitos LGBT","Direitos da Mulher","Educação", "Esporte e recreação", "Gestão de águas e saneamento", "Gestão de resíduos e reciclagem","Inclusão de pessoas com deficiência","População em situação de rua","Questões Raciais e Étnicas","Saúde","Segurança","Segurança Alimentar e Nutricional","Trabalho e renda","Transporte e mobilidade","Uso de tecnologia para mudança social"]

  return (
    <Container>
      <Banner title={"Saiba quais são os problemas públicos da cidade"} background={ProblemasBackground} />
      <BodyProblems>
        <ContainerTextProblems>
          <Title>Problemas Públicos</Title>
          <Text>
          Em toda cidade existe uma grande rede de atores sociais que se mobilizam e trabalham coletivamente em torno dos problemas públicos, buscando encontrar soluções para eles.
          <br /><br />
          A partir de dados secundários disponibilizados pelo (Instituto de Pesquisa Econômica Aplicada (IPEA), identificamos um conjunto de 461 organizações e iniciativas que estariam aptas a contribuir com nosso objetivo de pesquisa, por estarem categorizadas com o rótulo de "direitos sociais". Ainda não sabemos se todas as organizações desta base de dados estão ativas ou inativas, mas paulatinamente estamos fazendo contato e convidando a participar da pesquisa. Até o começo de setembro de 2024, foram 55 iniciativas de inovação social e 11 atores de suporte entrevistados. Entre as iniciativas entrevistadas, se destacaram problemas públicos dentro das arenas das questões ambientais, educação, assistência social, direitos da criança e do adolescente e cultura e arte. 
          <br /><br />
          Com esta ferramenta é possível filtrar a partir dos problemas públicos que as iniciativas cadastradas estão atuando.
          <br /><br />
          <b>Conheça as arenas públicas de Petrópolis formadas pelos diferentes atores que se mobilizam em torno dos problemas públicos da cidade </b>
          </Text>
        </ContainerTextProblems>
        <TitleList>Lista de problemas públicos</TitleList>
        <ContainerListProblems>
          <ListProblems>
            {optionsProblemasPublicos.map((problema, index) => (
              <InputList>{problema}</InputList>
            ))}
          </ListProblems>
        </ContainerListProblems>
      </BodyProblems>
    </Container>
  )
}

export default PublicProblems