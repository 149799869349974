import React, { useEffect, useState } from "react";
import {
  Container,
  ContainerInputs,
  ContainerExpand,
  Input,
  InputRadio,
  Label,
  SubText,
  TextInput,
  LabelText,
  InputText,
  Option,
  InputSelect,
  InputTextArea,
  Checkbox,
  ContainerAccordion,
  ContainerDuo,
  LabelTextDuo
} from "./styled";
import Accordion from "react-bootstrap/Accordion";


function RadioForm({
  label,
  id,
  idExpandInput,
  idExpandInputDuo,
  expand,
  labelInput,
  labelInputDuo,
  subText,
  typeExpand,
  options,
  height,
  width,
  widthDuo,
  registroRadio,
  type,
  placeholder,
  registro,
  description,
  onCheckboxChange,
  registroDuo,
  registerType
}) {
  const [visible, setVisible] = useState(true);
  const [require, setRequire] = useState(false);
  const [accordionEnabled, setAccordionEnabled] = useState(false);

  useEffect(() => {
    if (id === "autorizoDivulgacao") {
      setRequire(true)
    }
  }, [])

  function visibility(value) {
    setVisible(value)
  }

  const handleAccordionClick = (eventKey) => {
    if (visible === false) {
      setAccordionEnabled(!accordionEnabled);
    }
  };

  const handleCheckboxChange = (checked, value) => {
    onCheckboxChange(checked, value);
  };

  return (
    <Container>
      <Label htmlFor="input-radio">
        {label}
        <SubText>{subText}</SubText>
      </Label>
      <ContainerInputs>
        <Input>
          <InputRadio
            type="radio"
            value={true}
            // registerType={registerType}
            // Comentado pois estava dando erro na DOM
            onClick={() => {
              visibility(false)
            }}
            required={require}
            {...registroRadio}
          />
          <TextInput>Sim</TextInput>
        </Input>
        <Input>
          <InputRadio
            type="radio"
            value={false}
            // registerType={registerType}
            // Comentado pois estava dando erro na DOM
            onClick={() => {
              visibility(true)
            }}
            required={require}
            {...registroRadio}
          />
          <TextInput>Não</TextInput>
        </Input>
      </ContainerInputs>

      {expand && (
        <ContainerExpand>
          {typeExpand === "select" && (
            <Label htmlFor={id} width={width}>
              {labelInput}
              <InputSelect id={id} type={type} {...registro} disabled={visible}>
                <Option value="">Selecione...</Option>
                {options.map((option, index) => (
                  <Option value={option}>{option}</Option>
                ))}
              </InputSelect>
            </Label>
          )}

          {typeExpand === "input" && (
            <Label htmlFor={idExpandInput} width={width}>
              {labelInput}
              <InputText
                id={idExpandInput}
                type={type}
                required={require}
                placeholder={placeholder}
                disabled={visible}
                {...registro}
              />
            </Label>
          )}

          {typeExpand === "textArea" && (
            <Label htmlFor={id} width={width}>
              {labelInput}
              <InputTextArea placeholder={placeholder} id={id} type="text" height={height} {...registro} disabled={visible} />
            </Label>
          )}

          {typeExpand === "check" && (
            <ContainerAccordion disabled={visible}>
              <Accordion onSelect={handleAccordionClick} activeKey={accordionEnabled ? '0' : null} >
                <Accordion.Item eventKey="0" >
                  <Accordion.Header>{description}</Accordion.Header>
                  <Accordion.Body >
                    {Array.isArray(options) &&
                      options.map((option, index) => (
                        <Checkbox key={index}>
                          <input
                            type="checkbox"
                            value={option}
                            onChange={(e) => handleCheckboxChange(e.target.checked, option)}
                          />
                          <span>{option}</span>
                        </Checkbox>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ContainerAccordion>
          )}

          {typeExpand === "selectPlusInput" && (
            <ContainerDuo>
              <LabelText htmlFor={idExpandInput} width={width}>
                {labelInput}
                <ContainerAccordion disabled={visible}>
                  <Accordion onSelect={handleAccordionClick} activeKey={accordionEnabled ? '0' : null}>
                    <Accordion.Item eventKey="0" >
                      <Accordion.Header>{description}</Accordion.Header>
                      <Accordion.Body >
                        {Array.isArray(options) &&
                          options.map((option, index) => (
                            <Checkbox key={index}>
                              <input
                                type="checkbox"
                                value={option}
                                onChange={(e) => handleCheckboxChange(e.target.checked, option)}
                              />
                              <span>{option}</span>
                            </Checkbox>
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </ContainerAccordion>
              </LabelText>
              <LabelTextDuo htmlFor={idExpandInputDuo}>
                {labelInputDuo}
                <InputText
                  id={idExpandInputDuo}
                  type={type}
                  placeholder={placeholder}
                  disabled={visible}
                  {...registroDuo}
                />
              </LabelTextDuo>
            </ContainerDuo>
          )}
        </ContainerExpand>
      )}
    </Container>
  );
}

export default RadioForm;
