import styled from "styled-components";
import theme from "../../global/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;

  a {
    color: #000;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 3%;
`;

export const Title = styled.h1`
  margin-bottom: 3%;
  font-size: 30px;
  border-left: 2px solid ${theme.colors.verdeHover};
  padding-left: 15px;
  color: #424242;
`;
export const Text = styled.p`
  text-align: justify;
  margin: 0;
  margin-bottom: 2%;
`;

export const Subtitle = styled.h1`
  margin-bottom: 3%;
  margin-top: 6%;
  font-size: 20px;
  align-self: flex-start;
  border-left: 2px solid ${theme.colors.verdeHover};
  padding-left: 15px;
  color: #424242;
`;

export const List = styled.li`
  margin-left: 20px;
`;
