import React from "react";
import { PiAsteriskThin } from "react-icons/pi";
import { Input, Label, Option } from "./styled";

function SelectForm({ label, id, type, options, width, require, registro, onSelectChange, atuacao }) {

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    if (onSelectChange) {
      onSelectChange(id, selectedValue, atuacao);
    }
  };

  return (
    <Label htmlFor={id} require={require.toString()} width={width}>
      {require ? <>{label} <PiAsteriskThin /></> : label}
      <Input id={id} type={type} required={require} {...registro} onChange={handleChange}>
        <Option value="">Selecione...</Option>
        {options.map((option, index) => (
          <Option value={option} key={index}>{option}</Option>
        ))}
      </Input>
    </Label>
  );
}

export default SelectForm;
