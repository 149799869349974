import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { BodyContact, ButtonSend, ContactType, Contacts, Container, ContainerForms, Forms, IconEmail, IconInstagram, IconMarker, Information, InputText, InputTextArea, Label, ModalOverlay, StyledAlert, Subtitle, Title, TitleInformation } from './styled';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function Contact() {
  const [show, setShow] = useState(false);

  const sendEmail = (e) => {
    try {
      e.preventDefault();

      emailjs
        .sendForm(
          "service_0n8oe1v",
          "template_w9shj39",
          e.target,
          "2hTkCtSXEYDAjwdD_"
        )
        .then(
          (result) => {
            console.log(result.text);
            setShow(true);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } catch {
      setShow(false);
    }
  };

  return (
    <Container>
      <BodyContact>
        <Title>Entre em contato</Title>
        <Subtitle>
          Alguma pergunta ou feedback? Escreva-nos uma mensagem!
        </Subtitle>
        <ContainerForms>
          <Information>
            <TitleInformation>Informações de Contato</TitleInformation>
            <Contacts>
              <ContactType>
                <IconEmail /> eisp@ippur.ufrj.br
              </ContactType>
              <ContactType>
                <IconMarker /> Petrópolis - RJ
              </ContactType>
              <ContactType>
                <IconInstagram /> @eispetropolis
              </ContactType>
            </Contacts>
          </Information>
          <Forms onSubmit={sendEmail}>
            <Label htmlFor="nome">
              Nome Completo
              <InputText
                id="nome"
                name="nome"
                type="text"
              />
            </Label>
            <Label htmlFor="email">
              E-mail
              <InputText
                id="email"
                name="email"
                type="text"
              />
            </Label>
            <Label htmlFor="mensagem">
              Mensagem
              <InputTextArea id="mensagem" name="mensagem" />
            </Label>
            <ButtonSend type="submit">Enviar</ButtonSend>

            <ModalOverlay style={{ display: show ? "flex" : "none" }}>
              <StyledAlert show={show} variant="success">
                <Alert.Heading>Email enviado com sucesso!</Alert.Heading>
                <p>Obrigado por enviar a sua mensagem!</p>
                <p>Entraremos em contato para mais informações.</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => {
                      setShow(false);
                      window.scrollTo(0, 0);
                    }}
                    variant="outline-success"
                  >
                    Fechar
                  </Button>
                </div>
              </StyledAlert>
            </ModalOverlay>
          </Forms>
        </ContainerForms>
      </BodyContact>
    </Container>
  );
}

export default Contact;
