import axios from "axios";

const apiProjeto = axios.create({
  baseURL: "https://conecta-is-5.onrender.com",
});

const atualizarTokenFn = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await apiProjeto.post("/auth/login", {
      refreshToken: token,
    });

    const { token } = response.data;

    if (!token) {
      localStorage.removeItem("token");
    }

    localStorage.setItem("token", token);

    return token;
  } catch (error) {
    localStorage.removeItem("token");
  }
};

apiProjeto.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const session = await atualizarTokenFn();

      if (session) {
        originalRequest.headers.Authorization = `Bearer ${session.accessToken}`;
        return apiProjeto(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

// apiProjeto.interceptors.request.use(function (config) {
//   const token = localStorage.getItem("token");

//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }

//   return config;
// });

export default apiProjeto;

export const addAtor = (data) => {
  console.log("Dados recebidos em addAtor:", data);
  apiProjeto.post("api/ator", data);
};

export const addInciativa = (data) => {
  console.log("Dados recebidos em addIniciativa:", data);
  apiProjeto.post("api/iniciativa", data);
};

export function getAtor() {
  const url = `api/ator`;

  return apiProjeto.get(url);
}

export function getIniciativa() {
  const url = `api/iniciativa`;

  return apiProjeto.get(url);
}

export function getUsuario() {
  const url = `api/usuarios`;

  return apiProjeto.get(url);
}

export function getQuantidadeAtores() {
  return apiProjeto.get("api/ator").then((response) => {
    const atoresFiltrados = response.data.filter(
      (ator) => ator.verificado === true && ator.autorizoDivulgacao === true
    );

    return atoresFiltrados.length;
  });
}

export function getQuantidadeIniciativas() {
  return apiProjeto.get("api/iniciativa").then((response) => {
    const iniciativasFiltradas = response.data.filter(
      (iniciativa) =>
        iniciativa.verificado === true &&
        iniciativa.autorizoDivulgacao === true
    );

    return iniciativasFiltradas.length;
  });
}

export function getIniciativaById(iniciativaId){
  const url = `api/iniciativa/${iniciativaId}`;
  return apiProjeto.get(url);
}

export function putAtorById(atorId, dadosAtualizados) {
  const url = `api/ator/${atorId}`;
  return apiProjeto.put(url, dadosAtualizados);
}

export function putAtorByIdAdmin(atorId, dadosAtualizados) {
  const url = `api/ator/edit/${atorId}`;
  return apiProjeto.put(url, dadosAtualizados);
}

export function putIniciativaById(iniciativaId, dadosAtualizados) {
  const url = `api/iniciativa/${iniciativaId}`;
  return apiProjeto.put(url, dadosAtualizados);
}

export function putIniciativaByIdAdmin(iniciativaId, dadosAtualizados) {
  const url = `api/iniciativa/edit/${iniciativaId}`;
  return apiProjeto.put(url, dadosAtualizados);
}

export const filterIniciativa = (data) => {
  console.log("Dados recebidos em filterIniciativa:", data);
  return apiProjeto.post("api/iniciativa/filter", data);
};

export const filterAtor = (data) => {
  console.log("Dados recebidos em filterAtor:", data);
  return apiProjeto.post("api/ator/filter", data);
};

export const login = async (data) => {
  // Faz o login do usuário e atualiza o token

  const response = await apiProjeto.post("/auth/login", data);

  // Atualiza o token após o login do usuário
  await atualizarTokenFn();

  return response;
};

export const addUser = async (data) => {

  const token = localStorage.getItem("token");

  if (token) {
    // apiProjeto.defaults.headers.common["Authorization"] = `Bearer ${token}`;   

    return apiProjeto.post("/auth/cadastro", data);
  } else {
    console.error("Token not available");
  }
};

export const updateUser = async (updtUser) => {
  const token = localStorage.getItem("token");

  if (token) {
    // apiProjeto.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    return apiProjeto.put("/api/usuarios/" + updtUser.usuarioId, updtUser);
  } else {
    console.error("Token not available");
  }
}

export const deleteUser = async (deleteId) => {
  const token = localStorage.getItem("token");

  if (token) {
    // apiProjeto.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    return apiProjeto.delete("/api/usuarios/" + deleteId);
  } else {
    console.error("Token not available");
  }
}