import styled from "styled-components";
import theme from "../../global/colors";
import Tabs from "react-bootstrap/Tabs";

export const Container = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
`;

export const ContainerInfosMap = styled.div`
  display: flex;
  margin-top: 50px;
`;

export const ContainerMap = styled.div`
  display: flex;
  margin-top: 40px;
`;

export const CustomTabs = styled(Tabs)`
  .nav-item {
    flex-direction: column;
  }
  .nav-link.active {
    background-color: ${(props) =>
      props.activeKey === "iniciativas"
        ? `${theme.colors.azulHover}`
        : `${theme.colors.verdeHover}`};
      
    color: #fff;
    border: none;
  }
  .nav-link {
    color: #000;
  }
  .tab-content {
    width: 325px;
  }
`;
export const ContainerTabs = styled.div`
  .tab-content {
    width: 325px;
  }
`;

export const ContainerOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-left: 20px;
`;
export const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  margin: 0;
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  width: 100%;
  padding: 3% 0 3% 0;
  margin-top: 1%;
`;

export const InputFilterInitiative = styled.input.attrs({ type: "radio" })`
  width: 20px;
  height: 20px;

  &:checked {
    accent-color: ${theme.colors.azulHover};
  }
`;

export const InputFilterActor = styled.input.attrs({ type: "radio" })`
  width: 20px;
  height: 20px;

  &:checked {
    accent-color: ${theme.colors.verdeEscuro};
  }
`;
export const FilterBy = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8%;
`;
export const ContainerInputs = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  padding: 0 8% 0 8%;
`;
export const TitleFilter = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const TextInput = styled.p`
  margin: 0;
  font-size: 18px;
`;

export const ContainerOptionsFilter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 150px;
`;

export const SearchButton = styled.button`
  background-color: ${(props) =>
    props.color === "Iniciativas"
      ? `${theme.colors.azulHover}`
      : `${theme.colors.verdeHover}`};
  &:hover {
    background-color: ${(props) =>
      props.color === "Iniciativas"
        ? `${theme.colors.azulSecondaryHover}`
        : `${theme.colors.verdeSecondaryHover}`};
  }
  width: 100%;
  border: none;
  border-radius: 10px;
  margin-top: 2%;
  padding: 1% 0 1% 0;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
`;

export const ResetButton = styled.button`
  background-color: ${theme.colors.borderGrey};
  &:hover{
    background: #9e9e9e;
  }
  width: 100%;
  border: none;
  border-radius: 10px;
  margin-top: 2%;
  padding: 5px 0 5px 0;
  color: #424242;
  font-size: 20px;
  font-weight: 600;
`;

export const InputText = styled.input`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: ${(props) =>
    props.disabled === true ? "#e0e0e0" : "#fff"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 189, 189, 0.577468487394958);
  }
`;
export const Label = styled.label`
  color: ${(props) =>
    props.require === true ? `${theme.colors.red}` : `${theme.colors.black}`};
  font-size: 14px;
  font-weight: 400;
  width: ${(props) => props.width || "100%"};
  margin: 6px 0;
`;

export const InputSelect = styled.select`
  border: 0.5px solid ${theme.colors.borderGrey};
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;

  &:focus {
    border-color: ${theme.colors.borderGrey};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 189, 189, 0.577468487394958);
  }
`;

export const Option = styled.option`
  font-size: 14px;
  font-weight: 400;
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;
