import React from "react";
import Faperj from "../../assets/img/faperjLogo.png";
import Ippur from "../../assets/img/ippurLogo.png";
import Ipg from "../../assets/img/ipgLogo.png";
import Ufrj from "../../assets/img/Ufrj.jpg";
import Serratec from '../../assets/img/logoSerratec.png'
import Obisfron from '../../assets/img/obisfron.png'
import ObsFloripa from '../../assets/img/observaFloripa.png'
import Lapis from '../../assets/img/Lapis.png'
import Unifas from '../../assets/img/Unifas.png'
import Nisp from '../../assets/img/Nisp.png'
import Neisf from '../../assets/img/Neisf.png'
import Liss from '../../assets/img/Liss.png'
import Capes from '../../assets/img/Capes.png'
import Cnpq from '../../assets/img/Cnpq.png'

import { Container, ContainerFinanciers, ImageFinancier, Infos, Line, TitleFinancier } from "./styled";

function Financiers() {
  return (
    <Container>
      <Infos>
        <TitleFinancier>Realização</TitleFinancier>
        <Line />
        <ContainerFinanciers>
        <ImageFinancier
            alt="Logo do Lapis"
            src={Lapis}
          />
          <ImageFinancier
            alt="Logo do IPG(Instituti Philippe Guédon Gestão Participativa)"
            src={Ipg}
          />
          <ImageFinancier
            alt="Logo do IPPUR(Instituto de Pesquisa e Planejamento Urbano e Regional)"
            src={Ippur}
          />


        </ContainerFinanciers >
      </Infos>
      <Infos>
        <TitleFinancier>Parceiros</TitleFinancier>
        <Line />
        <ContainerFinanciers>
          <ImageFinancier
            alt="Logo do SERRATEC(Parque Tecnológico Região Serrana)"
            src={Serratec}
          />
          <ImageFinancier
            alt="Logo do Obisfron"
            src={Obisfron}
          />
          <ImageFinancier
            alt="Logo do Observa Floripa"
            src={ObsFloripa}
          />
        </ContainerFinanciers >
        <ContainerFinanciers>
        <ImageFinancier
            alt="Logo do Nisp"
            src={Nisp}
            style={{width: '12%'}}
          />
        <ImageFinancier 
          alt="Logo do Neisf" 
          src={Neisf}  
          style={{ width: '7%'}}/>
        <ImageFinancier
            alt="Logo do Liss"
            src={Liss}
            style={{ width: '12%'}}/>
            <ImageFinancier
            alt="Logo do Unifas"
            src={Unifas}
          />
        </ContainerFinanciers>
      </Infos>
      <Infos>
        <TitleFinancier>Financiadores</TitleFinancier>
        <Line />
        <ContainerFinanciers>
          <ImageFinancier
            alt="Logo da FAPERJ(Fundação Carlos Chagas Filho de Amparo à Pesquisa do Estado do Rio de Janeiro)"
            src={Faperj}
          />
          <ImageFinancier
            alt="Logo da Ufrj"
            src={Ufrj}
          />
           <ImageFinancier
            alt="Logo da Capes"
            src={Capes}
            style={{ width: '12%', marginLeft: '-25px'}}
          />
          <ImageFinancier
            alt="Logo da Cnpq"
            src={Cnpq}
            style={{ width: '18%'}}
          />
        </ContainerFinanciers >
      </Infos>
    </Container>
  );
}

export default Financiers;
