import {
  InfoRegistersContainer,
  AutorizationButtons,
  AutorizationButtonsCRUD,
  AllowButton,
  EditButton,
  DeleteButton,
  DeleteButtonListaCompleta,
  CancelButton
} from "./styled";
import { Accordion } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import { getAtor, putAtorById, putAtorByIdAdmin } from "../../api/apiProjeto/apiProjeto";
import { Modal, Button } from "react-bootstrap";
import { ContainerAccordion } from "../AccordionAdmPanel/styled";
import { LoadingContext } from "../../context/LoadingContext";
import { AiFillSave, AiFillEdit, AiFillDelete } from 'react-icons/ai';


function RegistersFilterAdmPanelSuport({ tipoLista }) {
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAtorId, setSelectedAtorId] = useState(null);
  const [isEditing, setIsEditing] = useState(false); 
  const [formData, setFormData] = useState({});
  const { setIsLoading } = useContext(LoadingContext);


  const handleShowDeleteModal = (atorId) => {
    setSelectedAtorId(atorId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedAtorId(null);
    setShowDeleteModal(false);
  };

  const handleGetAtores = async () => {
    setIsLoading(true);
    try {
      const response = await getAtor();
      setData(response.data);
    } catch (error) {
      console.error("Erro ao buscar dados da API", error);
    }
    setIsLoading(false);
  };

  const handleCancelar = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    handleGetAtores();
  }, []);

  const handleAllowButtonClick = async (atorId) => {
    try {
      await putAtorById(atorId, { verificado: true, ativo: "verificado" });

      setData((prevData) => prevData.filter((item) => item.atorId !== atorId));
    } catch (error) {
      console.error("Erro ao permitir", error);
    }
  };

  const handleDeleteButtonClick = async (atorId) => {
    try {
      await putAtorById(atorId, { verificado: false, ativo: "excluido" });

      setData((prevData) => prevData.filter((item) => item.atorId !== atorId));

      handleCloseDeleteModal();

      setTimeout(() => {
        alert("Removido com sucesso!");
      }, 100);
    } catch (error) {
      console.error("Erro ao deletar", error);
    }
  };

  const handleEditClick = (item) => {
    setFormData(item);
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try { 
      await putAtorByIdAdmin(formData.atorId, formData);

      setIsEditing(false);
      alert("Atualizado com sucesso!");
      handleGetAtores();
    } catch (error) {
      console.error("Erro ao atualizar", error.response ? error.response.data : error.message);
    }
  };
  

  return (
    <>
      {data.sort((a, b) => a.nome.localeCompare(b.nome)).map((item, index) => (
        <ContainerAccordion
          tipostatus={item.ativo}
          height={"60px"}
          key={index}
        >
          {tipoLista === "cadastroAtores" && item.verificado == false && (
            <Accordion defaultActiveKey={`subItem-${index}0`}>
              <Accordion.Item
                key={`subItem-${index}`}
                eventKey={`subItem-${index}`}
              >
                <Accordion.Header>{item.nome}</Accordion.Header>
                <Accordion.Body>
                  <InfoRegistersContainer>
                  {isEditing ? (
                    <div>
                      <div>
                        <label>Nome do Ator:</label>
                        <input
                          type="text"
                          name="nome"
                          value={formData.nome}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Sigla:</label>
                        <input
                          type="text"
                          name="sigla"
                          value={formData.sigla}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Data de Início das Atividades:</label>
                        <input
                          type="date"
                          name="dataInicio"
                          value={formData.dataInicio}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Status do Ator:</label>
                        <input
                          type="text"
                          name="ativo"
                          value={formData.ativo}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Telefone:</label>
                        <input
                          type="tel"
                          name="telefone"
                          value={formData.telefone}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Telefone Comercial:</label>
                        <input
                          type="tel"
                          name="telComercial"
                          value={formData.telComercial}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Celular:</label>
                        <input
                          type="tel"
                          name="celular"
                          value={formData.celular}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Email:</label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Rede Social 1:</label>
                        <input
                          type="text"
                          name="redeSocial"
                          value={formData.redeSocial}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Rede Social 2:</label>
                        <input
                          type="text"
                          name="redeSocial2"
                          value={formData.redeSocial2}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Rede Social 3:</label>
                        <input
                          type="text"
                          name="redeSocial3"
                          value={formData.redeSocial3}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Site:</label>
                        <input
                          type="url"
                          name="site"
                          value={formData.site}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Representante:</label>
                        <input
                          type="text"
                          name="representante"
                          value={formData.representante}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>CEP:</label>
                        <input
                          type="text"
                          name="cep"
                          value={formData.cep}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Logradouro:</label>
                        <input
                          type="text"
                          name="logradouro"
                          value={formData.logradouro}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Número:</label>
                        <input
                          type="text"
                          name="numero"
                          value={formData.numero}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Complemento:</label>
                        <input
                          type="text"
                          name="complemento"
                          value={formData.complemento}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Bairro:</label>
                        <input
                          type="text"
                          name="bairro"
                          value={formData.bairro}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Cidade:</label>
                        <input
                          type="text"
                          name="localidade"
                          value={formData.localidade}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>UF:</label>
                        <input
                          type="text"
                          name="uf"
                          value={formData.uf}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>País:</label>
                        <input
                          type="text"
                          name="pais"
                          value={formData.pais}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Atividades de Suporte:</label>
                        <input
                          type="text"
                          name="atividadesPublicos"
                          value={formData.atividadesPublicos}
                          onChange={handleInputChange}
                        />
                        <hr />
                      </div>
                      <button onClick={handleSaveClick}>Salvar</button>
                    </div>
                  ) : (
                    <>
                    <div>
                          <p>Nome do ator: {item.nome}</p>
                          <hr />

                          <p>Sigla: {item.sigla}</p>
                          <hr />

                          <p>Data de Início das atividades: {item.dataInicio}</p>
                          <hr />

                          <p>Status do ator: {item.ativo ?? "teste"}</p>
                          <hr />

                          <p>Telefone: {item.telefone}</p>
                          <hr />

                          <p>Telefone Comercial: {item.telComercial}</p>
                          <hr />

                          <p>Celular: {item.celular}</p>
                          <hr />

                          <p>Email: {item.email}</p>
                          <hr />

                          <p>Rede Social 1: {item.redeSocial}</p>
                          <hr />

                          <p>Rede Social 2: {item.redeSocial2}</p>
                          <hr />

                          <p>Rede Social 3: {item.redeSocial3}</p>
                          <hr />

                          <p>Site: {item.site}</p>
                          <hr />

                          <p>Representante: {item.representante}</p>
                          <hr />

                          <p>CEP: {item.cep}</p>
                          <hr />

                          <p>Logradouro: {item.logradouro}</p>
                          <hr />

                          <p>Número: {item.numero}</p>
                          <hr />

                          <p>Complemento: {item.complemento}</p>
                          <hr />
                        </div><div>
                            <p>Bairro: {item.bairro}</p>
                            <hr />

                            <p>Cidade: {item.localidade}</p>
                            <hr />

                            <p>UF: {item.uf}</p>
                            <hr />

                            <p>País: {item.pais}</p>
                            <hr />

                            <p>Atividades de suporte: {item.atividadesPublicos}</p>
                            <hr />

                            <p>
                              Organizações de suporte:
                              {`${item.iniciativa1}, ${item.iniciativa2}, ${item.iniciativa3} `}
                            </p>
                            <hr />

                            <p>Principais parceiros: {item.indicacaoParceiro}</p>
                            <hr />

                            <p>
                              Causas específicas:
                              {item.responseCateg
                                .map((categoria) => categoria.descricaoCausaAtor)
                                .join(", ")}
                            </p>
                            <hr />

                            <p>
                              Formas de atuação:
                              {item.responseAtuacao
                                .map((categoria) => categoria.descricaoAtuacao)
                                .join(", ")}
                            </p>
                            <hr />

                            <p>
                              Origem da organização:
                              {item.responseOrigem
                                .map((categoria) => categoria.descricaoOrigem)
                                .join(", ")}
                            </p>
                            <hr />

                            <p>
                              Público alvo:
                              {item.responseFrequencia
                                .map(
                                  (categoria) => categoria.descricaoFrequenciaPublico
                                )
                                .join(", ")}
                            </p>
                            <hr />
                            <p>
                              Tipo da organização:
                              {item.responseOrg
                                .map(
                                  (categoria) => categoria.descricaoOrganizacaoAtor
                                )
                                .join(", ")}
                            </p>
                            <hr />

                            <p>Ações de inovação: {item.descricaoInovacaoPropria}</p>
                            <hr />

                            <p>Indicações de atores: {item.indicacaoAtor}</p>
                            <hr />

                            <p>Escala de abrangência: {item.abrangencia}</p>
                            <hr />
                            <p>
                              Investimento de recursos:
                              {item.responseRecursos
                                .map((categoria) => categoria.descricaoRecurso)
                                .join(", ")}
                            </p>
                            <hr />

                            <p>
                              Valor de investimento - Recurso:{" "}
                              {item.valorAnoInvestimento}
                            </p>
                            <hr />
                            <p>
                              Autorização para Divulgação:
                              {item.autorizoDivulgacao ? " Aceito" : " Recusado"}
                            </p>
                            <hr />
                          </div>
                          </>
                  )}
                  </InfoRegistersContainer>
                  <AutorizationButtonsCRUD>
                    <DeleteButton
                      onClick={() => handleShowDeleteModal(item.atorId)}
                    >
                      DESCARTAR
                    </DeleteButton>
                    <EditButton
                      onClick={() => handleEditClick(item)}
                    >
                      EDITAR
                    </EditButton>
                    <AllowButton
                      onClick={() => handleAllowButtonClick(item.atorId)}
                    >
                      ACEITAR
                    </AllowButton>
                  </AutorizationButtonsCRUD>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {tipoLista === "listaCompleta" && item.verificado !== false && (
            <Accordion defaultActiveKey={`subItem-${index}0`}>
              <Accordion.Item
                key={`subItem-${index}`}
                eventKey={`subItem-${index}`}
              >
                <Accordion.Header>{item.nome}</Accordion.Header>
                <Accordion.Body>
                <InfoRegistersContainer>
                  {isEditing ? (
                    <div>
                      <div>
                        <label>Nome do Ator:</label>
                        <input
                          type="text"
                          name="nome"
                          value={formData.nome}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Sigla:</label>
                        <input
                          type="text"
                          name="sigla"
                          value={formData.sigla}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Data de Início das Atividades:</label>
                        <input
                          type="date"
                          name="dataInicio"
                          value={formData.dataInicio}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Status do Ator:</label>
                        <input
                          type="text"
                          name="ativo"
                          value={formData.ativo}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Telefone:</label>
                        <input
                          type="tel"
                          name="telefone"
                          value={formData.telefone}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Telefone Comercial:</label>
                        <input
                          type="tel"
                          name="telComercial"
                          value={formData.telComercial}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Celular:</label>
                        <input
                          type="tel"
                          name="celular"
                          value={formData.celular}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Email:</label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Rede Social 1:</label>
                        <input
                          type="text"
                          name="redeSocial"
                          value={formData.redeSocial}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Rede Social 2:</label>
                        <input
                          type="text"
                          name="redeSocial2"
                          value={formData.redeSocial2}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Rede Social 3:</label>
                        <input
                          type="text"
                          name="redeSocial3"
                          value={formData.redeSocial3}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Site:</label>
                        <input
                          type="url"
                          name="site"
                          value={formData.site}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Representante:</label>
                        <input
                          type="text"
                          name="representante"
                          value={formData.representante}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>CEP:</label>
                        <input
                          type="text"
                          name="cep"
                          value={formData.cep}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Logradouro:</label>
                        <input
                          type="text"
                          name="logradouro"
                          value={formData.logradouro}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Número:</label>
                        <input
                          type="text"
                          name="numero"
                          value={formData.numero}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Complemento:</label>
                        <input
                          type="text"
                          name="complemento"
                          value={formData.complemento}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Bairro:</label>
                        <input
                          type="text"
                          name="bairro"
                          value={formData.bairro}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Cidade:</label>
                        <input
                          type="text"
                          name="localidade"
                          value={formData.localidade}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>UF:</label>
                        <input
                          type="text"
                          name="uf"
                          value={formData.uf}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>País:</label>
                        <input
                          type="text"
                          name="pais"
                          value={formData.pais}
                          onChange={handleInputChange}
                        />
                        <hr />

                        <label>Atividades de Suporte:</label>
                        <input
                          type="text"
                          name="atividadesPublicos"
                          value={formData.atividadesPublicos}
                          onChange={handleInputChange}
                        />
                        <hr />
                      </div>
                    </div>
                  ) : (
                    <>
                    <div>
                          <p>Nome do ator: {item.nome}</p>
                          <hr />

                          <p>Sigla: {item.sigla}</p>
                          <hr />

                          <p>Data de Início das atividades: {item.dataInicio}</p>
                          <hr />

                          <p>Status do ator: {item.ativo ?? "teste"}</p>
                          <hr />

                          <p>Telefone: {item.telefone}</p>
                          <hr />

                          <p>Telefone Comercial: {item.telComercial}</p>
                          <hr />

                          <p>Celular: {item.celular}</p>
                          <hr />

                          <p>Email: {item.email}</p>
                          <hr />

                          <p>Rede Social 1: {item.redeSocial}</p>
                          <hr />

                          <p>Rede Social 2: {item.redeSocial2}</p>
                          <hr />

                          <p>Rede Social 3: {item.redeSocial3}</p>
                          <hr />

                          <p>Site: {item.site}</p>
                          <hr />

                          <p>Representante: {item.representante}</p>
                          <hr />

                          <p>CEP: {item.cep}</p>
                          <hr />

                          <p>Logradouro: {item.logradouro}</p>
                          <hr />

                          <p>Número: {item.numero}</p>
                          <hr />

                          <p>Complemento: {item.complemento}</p>
                          <hr />
                        </div><div>
                            <p>Bairro: {item.bairro}</p>
                            <hr />

                            <p>Cidade: {item.localidade}</p>
                            <hr />

                            <p>UF: {item.uf}</p>
                            <hr />

                            <p>País: {item.pais}</p>
                            <hr />

                            <p>Atividades de suporte: {item.atividadesPublicos}</p>
                            <hr />

                            <p>
                              Organizações de suporte:
                              {`${item.iniciativa1}, ${item.iniciativa2}, ${item.iniciativa3} `}
                            </p>
                            <hr />

                            <p>Principais parceiros: {item.indicacaoParceiro}</p>
                            <hr />

                            <p>
                              Causas específicas:
                              {item.responseCateg
                                .map((categoria) => categoria.descricaoCausaAtor)
                                .join(", ")}
                            </p>
                            <hr />

                            <p>
                              Formas de atuação:
                              {item.responseAtuacao
                                .map((categoria) => categoria.descricaoAtuacao)
                                .join(", ")}
                            </p>
                            <hr />

                            <p>
                              Origem da organização:
                              {item.responseOrigem
                                .map((categoria) => categoria.descricaoOrigem)
                                .join(", ")}
                            </p>
                            <hr />

                            <p>
                              Público alvo:
                              {item.responseFrequencia
                                .map(
                                  (categoria) => categoria.descricaoFrequenciaPublico
                                )
                                .join(", ")}
                            </p>
                            <hr />
                            <p>
                              Tipo da organização:
                              {item.responseOrg
                                .map(
                                  (categoria) => categoria.descricaoOrganizacaoAtor
                                )
                                .join(", ")}
                            </p>
                            <hr />

                            <p>Ações de inovação: {item.descricaoInovacaoPropria}</p>
                            <hr />

                            <p>Indicações de atores: {item.indicacaoAtor}</p>
                            <hr />

                            <p>Escala de abrangência: {item.abrangencia}</p>
                            <hr />
                            <p>
                              Investimento de recursos:
                              {item.responseRecursos
                                .map((categoria) => categoria.descricaoRecurso)
                                .join(", ")}
                            </p>
                            <hr />

                            <p>
                              Valor de investimento - Recurso:{" "}
                              {item.valorAnoInvestimento}
                            </p>
                            <hr />
                            <p>
                              Autorização para Divulgação:
                              {item.autorizoDivulgacao ? " Aceito" : " Recusado"}
                            </p>
                            <hr />
                          </div>
                          </>
                  )}
                  </InfoRegistersContainer>
                  <AutorizationButtonsCRUD>
                    {isEditing ? 
                      <>
                        <AllowButton onClick={() => handleSaveClick()}>
                        SALVAR <AiFillSave /> 
                        </AllowButton>
                        <CancelButton onClick={() => handleCancelar()}>
                          Cancelar
                        </CancelButton>
                      </>
                      :
                      <>
                        <EditButton onClick={() => handleEditClick(item)}>
                        EDITAR <AiFillEdit />
                        </EditButton>
                        <DeleteButtonListaCompleta onClick={() => handleShowDeleteModal(item.atorId)}>
                          DELETAR <AiFillDelete />
                        </DeleteButtonListaCompleta>
                      </>
                    } 
                  </AutorizationButtonsCRUD>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </ContainerAccordion>
      ))}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Remoção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja remover o ator cadastrado da lista de aprovação?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Não
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteButtonClick(selectedAtorId)}
          >
            Sim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RegistersFilterAdmPanelSuport;
