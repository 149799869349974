import styled from "styled-components";
import theme from "../../global/colors";

export const ContainerAccordion = styled.div`
  margin-top: 4px;
  .accordion-header {
    border-radius: 2px;

    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "100%"};
  }
  .accordion-item {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 0.25rem;
    border-top-left-radius: 14px !important;
    border-bottom-left-radius: 12px !important;
  }
  .accordion-button.collapsed {
    border-radius: 10px !important;
  }

  .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "100%"};

    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-left: solid 6px
      ${(props) => {
        switch (props.tipostatus) {
          case "inativo":
            return theme.colors.borderGrey;
          case "mapeado":
            return theme.colors.green;
          case "observado":
            return theme.colors.vine;
          case "acompanhado":
            return theme.colors.blue;
          case "excluido":
            return theme.colors.primary;
          case "verificado":
            return theme.colors.green;
          default:
            return theme.colors.lightGrey;
        }
      }};
  }

  .accordion-button:not(.collapsed) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #dcdcdc;
    box-shadow: none;
    font-weight: 500;
    color: black;
    background-color: ${(props) => {
      switch (props.tipostatus) {
        case "inativo":
          return theme.colors.borderGrey;
        case "mapeado":
          return theme.colors.green;
        case "observado":
          return theme.colors.vine;
        case "acompanhado":
          return theme.colors.blue;
        case "excluido":
          return theme.colors.primary;
        case "verificado":
          return theme.colors.green;
      }
    }};
  }
  .accordion-button:focus {
    box-shadow: none;
  }
`;

export const Label = styled.label`
  color: ${theme.colors.red};
  font-size: 14px;
  font-weight: 400;
  width: ${(props) => props.width || "100%"};
  min-width: 250px;
`;
