import React from "react";
import {
  Body,
  Container,
  ContainerText,
  List,
  Text,
  Title,
  Subtitle,
} from "./styled";
import MetodologiaBackground from "../../assets/img/background-metodologia.jpg";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import theme from "../../global/colors";

function Methodology() {
  return (
    <Container>
      <Banner
        title={"Conheça nossa metodologia de pesquisa social implicada, colaborativa e em rede com atores sociais situados"}
        background={MetodologiaBackground}
      />
      <Body>
        <ContainerText>
          <Title>Metodologia</Title>
          <Text>
          O OBISP surge a partir da inspiração, da parceria e da agenda de trabalho do Observatório de Inovação Social de Florianópolis (OBISF), plataforma digital da Universidade Estadual de Santa Catarina – Escola Superior de Administração e Gerência (UDESC-ESAG), idealizada e coordenada pelo Núcleo de Inovações Sociais na Esfera Pública (NISP), cujo objetivo é disseminar o estudo da inovação social a partir do enfrentamento de problemas públicos, tendo por base uma abordagem pragmatista. <Link
          to={"https://www.scielo.br/j/rap/a/W8HPVFsSQwhNGzRVF6WddxC/#"}
          target="_blank">
          (ANDION et al., 2017; ANDION, ALPERSTEDT; GRAEFF, 2020, ANDION, 2023)
        </Link>
            <br /><br />
            Buscamos compreender como as inovações sociais ocorrem (ou o que dificulta sua ocorrência) por meio das práticas e ações que as comunidades realizam no território ao longo do tempo, para enfrentar os problemas públicos, buscando uma mudança ou transformação social.
            Trabalhamos em rede com outros Observatórios de Inovação Social do Brasil e do Mundo, tais como o OBISF, OBISFRON, LISS, CRISES.
            <br /><br />
            A metodologia do NISP/UDESC é composta por quatro momentos principais que interrelacionam três escalas de análise: macro (institucional), meso (interações/transações) e micro (experiências).
            Com esta metodologia são co-produzidos informações e dados sobre as três escalas do ecossistema de inovação social. Esses dados são analisados gerando e difundindo conhecimento e promovendo interação e aprendizagem coletiva entre os atores que compõem a rede.
            <br /><br />
            Além de acompanhar, apoiar e fomentar experiências de inovação social em diferentes arenas públicas da cidade, o OBISP promove e participa de eventos e de processos de formação (dentro e fora da Universidade) envolvendo acadêmicos, pesquisadores, gestores, profissionais e outros interessados em inovação social.
            Desse modo, o OBISP promove: a) um processo de co-criação entre os pesquisadores da universidade e os atores que compõem o Ecossistema de Inovações Sociais de Petrópolis; b) a articulação contínua entre ensino, pesquisa e extensão; c) o diálogo interdisciplinar entre os cursos e áreas envolvidos; d) a aprendizagem pela experimentação, por meio da interação entre teoria e prática.
            <br /><br /><br />
            <b>Como fazemos a cartografia</b>
            <br /><br />
            A cartografia ocorre por meio de um processo colaborativo entre os pesquisadores do OBISP e os próprios atores que compõem EIS de Petrópolis que, juntos, vêm retraçando a rede do ecossistema e co-produzindo informações, reflexões e conhecimentos sobre as suas práticas.
            <br /><br />
            <List>
              {" "}
              <b>ETAPA 1</b> - Os atores mapeados são indicados entre si, por meio da metodologia (snowball) ou são identificados pelos pesquisadores, a partir de dados secundários colhidos na internet. Nessa etapa as iniciativas são cadastradas como mapeadas.
            </List>
            <List>
              {" "}
              <b>ETAPA 2</b> - Os atores mapeados são contactados por meio de visitas ou via entrevistas (por telefone ou on-line) e têm suas informações complementadas e validadas pela equipe do OBISP. Nesse momento, as iniciativas tornam-se observadas, complementando todas as informações do questionário de pesquisa.
            </List>
            <List>
              <b>ETAPA 3</b> - Algumas iniciativas de inovação social que possuem uma maior incidência nas arenas públicas em que atuam poderão ser acompanhadas, de forma sistemática, em estudos desenvolvidos pelos membros da equipe do OBISP.
            </List>
            <List>
              <b>ETAPA 4</b> - As iniciativas que deixam de existir e não possuem mais atividade comprovada são transformadas em inativas.
            {" "}
            </List>
            <br /><br />
            <b>Entenda as Categorias</b>
            <br /><br />

            <span style={{ color: theme.colors.red }}>Mapeadas </span> 
            Todas as iniciativas de inovação social indicadas ou auto-cadastradas na plataforma.
            <br /><br />

            <span style={{ color: theme.colors.yellow }}>Observadas </span> 
            Iniciativas que tiveram suas informações complementadas e validadas pela equipe do Observatório.
            <br /><br />

            <span style={{ color: theme.colors.azulClaro }}>Acompanhadas </span> 
            Iniciativas que foram ou estão sendo acompanhadas sistematicamente pelos pesquisadores.
            <br /><br />

            <span style={{ color: theme.colors.borderGrey }}>Inativas </span> 
            Iniciativas mapeadas/observadas que deixaram de atuar durante o período da pesquisa.
            <br /><br />

            Além de ser um instrumento de produção e difusão de conhecimento científico sobre as inovações sociais, o OBISP atua além dos muros da Universidade, tornando-se um espaço de reforço aos Laboratórios Vivos de Inovação Social, promovendo pesquisa pública, entre e com os diferentes atores que compõem o EIS, sejam eles provenientes da sociedade civil, do governo ou do meio empresarial. Buscamos, assim, aliar diversos saberes, teorias e práticas, a fim de entender melhor como as inovações sociais respondem aos vários problemas socioambientais da cidade, reforçando dinâmicas de experimentação para a democracia e para a sustentabilidade.
          </Text>
        </ContainerText>
        <Subtitle>Acesse aqui o trabalho pioneiro de Carolina Andion (2020), autora da metodologia:</Subtitle>
        <Link
          to={"https://www.scielo.br/j/rap/a/W8HPVFsSQwhNGzRVF6WddxC/#"}
          target="_blank"
        >
          Metodologia da Cartografia de Ecossistemas de Inovação Social
        </Link>
      </Body>
    </Container>
  );
}

export default Methodology;
